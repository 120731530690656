import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from "@curi/react-dom";
import firebase from 'firebase';
import __orderBy from 'lodash/orderBy';
import __find from 'lodash/find';

import './Employers.scss';

import { 
    Table,
    Icon,
    toaster
} from 'evergreen-ui';

const Employers = ({ router, setPageTitle, currentUser }) => {
    const db = firebase.firestore();
    const [employers, setEmployers] = useState([]);

    setPageTitle('My Employers');

    useEffect(() => {
        const getEmployers = async () => {
            try {
                if (currentUser) {
                    const snapshot = await db.collection('users').where('email', '==', currentUser.email).get();
                    const data = snapshot.docs[0].data();

                    if (!data.profileId) {
                        throw(new Error('Couldn\'t retrieve user\'s id'));
                    }

                    const tempEmployers = [];
                    const finalEmployers = [];
                    const employersSnapshot = await db.collection('employersByUsers').where('userId', '==', data.profileId).get();
                    employersSnapshot.forEach(async (doc) => {
                        //startDate is required in the db so no ternary
                        const startDate = new Date(doc.data().startDate.seconds * 1000).toLocaleDateString('en-US', {
                            month: 'numeric',
                            day: 'numeric',
                            year: '2-digit'
                        });
                        
                        const endDate = doc.data().endDate ? new Date(doc.data().endDate.seconds * 1000).toLocaleDateString('en-US', {
                            month: 'numeric',
                            day: 'numeric',
                            year: '2-digit'
                        }) : 'Present';
                        
                        let currentPosition = __find(doc.data().positions, (position) => {
                            return position.endDate === null;
                        });

                        if (!currentPosition) {
                            const positions = __orderBy(doc.data().positions, ['endDate'], 'desc');
                            currentPosition = positions[0];
                        }
                        
                        tempEmployers.push({
                            empByUserId: doc.data().empByUserId,
                            id: doc.data().employerId,
                            currentPosition: currentPosition.title,
                            start: startDate,
                            end: endDate
                        });
                    });

                    for (const employer of tempEmployers) {
                        let employerData = employer;
                        const companySnapshot = await db.collection('companies').where('companyId', '==', employerData.id).get();
                        const data = companySnapshot.docs[0].data();
                        
                        employerData.name = data.name;
                        finalEmployers.push(employerData);
                    }

                    setEmployers(finalEmployers);
                }
            } catch(error) {
                console.log(error);
                toaster.danger('Something went wrong retrieving your data. Please refresh the page or contact us.');
            }
        };

        getEmployers();

        return () => {};
    }, [currentUser, db]);

    const editEmployer = (id) => {
        const url = router.url({ 
            name: 'EditEmployer', 
            params: {
                id: id
            }
        });
        router.navigate({ url });
    };

    return (
        <div className="page-wrapper maxed employers">
            <p className="employers-desc">All your employers are shown here.<br />Click on one to view and edit.</p>
            <Table>
                <Table.Head className="table-head">
                    <Table.TextHeaderCell>
                        Company
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell>
                        Position
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell>
                        Start
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell>
                        End
                    </Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                    {employers.map(employer => (
                        <Table.Row 
                            key={employer.empByUserId}
                            isSelectable
                            onSelect={() => editEmployer(employer.empByUserId)}
                        >
                            <Table.TextCell className="custom-cell">{employer.name}</Table.TextCell>
                            <Table.TextCell className="custom-cell">{employer.currentPosition}</Table.TextCell>
                            <Table.TextCell className="custom-cell">{employer.start}</Table.TextCell>
                            <Table.TextCell className="custom-cell">{employer.end}</Table.TextCell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Link name="NewEmployer">
                <div className="new-entity-fab">
                    <Icon icon="plus" size={28} />
                </div>
            </Link>
        </div>
    );
}

Employers.propTypes = {
    setPageTitle: PropTypes.func.isRequired
};

export default Employers;