import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase';

import { 
    createRouter, 
    title, 
    scroll 
} from "@curi/router";
import { browser } from "@hickory/browser";
import routes from './routes';
import { createRouterComponent } from "@curi/react-dom";

firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
});

const router = createRouter(browser, routes, {
    sideEffect: [
        scroll(),
        title(({ response }) => {
            return `${response.meta.title}`;
        })
    ]
});

const Router = createRouterComponent(router);

ReactDOM.render((
    <Router>
        <App />
    </Router>
), document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
