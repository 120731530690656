import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from "@curi/react-dom";
import firebase from 'firebase';
import moment from 'moment';
import __startCase from 'lodash/startCase';
import __orderBy from 'lodash/orderBy';
import __isEmpty from 'lodash/isEmpty';
import __filter from 'lodash/filter';

import './Events.scss';

import {
    Table,
    TextDropdownButton,
    Select,
    Icon,
    toaster
} from 'evergreen-ui';

const Events = ({ router, setPageTitle, currentUser, mode }) => {
    const db = firebase.firestore();
    const [events, setEvents] = useState([]);
    const [userId, setUserId] = useState('');
    const [lastNumDays, setLastNumDays] = useState('all');
    const [dateOrder, setDateOrder] = useState('asc');
    const [typeOrder, setTypeOrder] = useState('asc');
    const [feelingOrder, setFeelingOrder] = useState('asc');
    const [searchText, setSearchText] = useState('');
    const [showNoEvents, setShowNoEvents] = useState(false);
    const [isLoadingEvents, setIsLoadingEvents] = useState(false);

    setPageTitle('My Timeline');

    useEffect(() => {
        const getEvents = async () => {
            if (searchText === "") {
                setIsLoadingEvents(true);
            }

            try {
                if (currentUser) {
                    const snapshot = await db.collection('users').where('email', '==', currentUser.email).get();
                    const data = snapshot.docs[0].data();

                    if (!data.profileId) {
                        setIsLoadingEvents(false);
                        throw(new Error('Couldn\'t retrieve user\'s id'));
                    }

                    let tempEvents = [];
                    let finalEvents = [];
                    let eventsSnapshot;

                    if (lastNumDays === 'all') {
                        eventsSnapshot = await db.collection('events').where('userId', '==', data.profileId).get();
                    } else {
                        const today = new Date();
                        const minDate = today.setDate(today.getDate() - parseInt(lastNumDays));
                        eventsSnapshot = await db.collection('events')
                            .where('userId', '==', data.profileId)
                            .where('eventDate', '>=', firebase.firestore.Timestamp.fromMillis(minDate))
                            .get();
                    }

                    eventsSnapshot.forEach(async (doc) => {
                        const eventMillis = doc.data().eventDate.seconds * 1000;
                        const eventDate = moment(eventMillis);

                        tempEvents.push({
                            id: doc.data().eventId,
                            title: doc.data().title,
                            realDate: eventDate,
                            date: eventDate.format('M/D/YY'),
                            time: eventDate.format('h:mm a'),
                            type: doc.data().type,
                            feeling: doc.data().feeling,
                            companyId: doc.data().companyId,
                            notes: doc.data().notes
                        });
                    });

                    for (const event of tempEvents) {
                        let eventData = event;
                        const companySnapshot = await db.collection('companies').where('companyId', '==', eventData.companyId).get();
                        const companyData = companySnapshot.docs[0].data();

                        eventData.company = companyData.name;
                        finalEvents.push(eventData);
                    }

                    if (searchText.trim() !== '') {
                        const searchedFinalEvents = __filter(finalEvents, (event) => {
                            const isInTitle = event.title.toLowerCase().includes(searchText.trim().toLowerCase());
                            const isInDesc = event.notes.toLowerCase().includes(searchText.trim().toLowerCase());

                            return isInTitle || isInDesc;
                        });
                        
                        setEvents(searchedFinalEvents);
                    } else {
                        setEvents(finalEvents);
                    }

                    setUserId(data.profileId);
                    setIsLoadingEvents(false);

                    setTimeout(() => {
                        if (events.length === 0) {
                            setShowNoEvents(true);
                        }
                    }, 500);
                }
            } catch(error) {
                console.log(error);
                setIsLoadingEvents(false);
                toaster.danger('Something went wrong retrieving your data. Please refresh the page or contact us.');
            }
        };

        getEvents();

        return () => {};
    }, [currentUser, db, events.length, lastNumDays, searchText]);

    const editEvent = (id) => {
        const url = router.url({
            name: 'EditEvent',
            params: {
                id: id
            }
        });
        router.navigate({ url });
    };

    const sortByDate = () => {
        const newDateOrder = dateOrder === 'asc' ? 'desc' : 'asc';
        setEvents(__orderBy(events, ['date'], [newDateOrder]));
        setDateOrder(newDateOrder);
    };

    const sortByType = () => {
        const newTypeOrder = typeOrder === 'asc' ? 'desc' : 'asc';
        setEvents(__orderBy(events, ['type'], [newTypeOrder]));
        setTypeOrder(newTypeOrder);
    };

    const sortByFeeling = () => {
        const newFeelingOrder = feelingOrder === 'asc' ? 'desc' : 'asc';
        setEvents(__orderBy(events, ['feeling'], [newFeelingOrder]));
        setFeelingOrder(newFeelingOrder);
    };

    if (!isLoadingEvents && events.length === 0) {
        return (
            <div className="no-events">
                We have no recorded events for you. You can add a new event by clicking the button below:
                <br /><br />
                <div className="button-component custom">
                    <Link className="get-started-button" name="NewEvent">Add New Event</Link>
                </div>
            </div>
        );
    }
    
    if (!isLoadingEvents && events.length > 0) {
        return (
            <div className="page-wrapper maxed events">
                <div className="filters">
                    <div className="date-pick">
                        <ul>
                            <li className={`${lastNumDays === 'all' ? 'active' : ''}`} onClick={() => setLastNumDays('all')}>All</li>
                            <li className={`${lastNumDays === '7' ? 'active' : ''}`} onClick={() => setLastNumDays('7')}>7 days</li>
                            <li className={`${lastNumDays === '30' ? 'active' : ''}`} onClick={() => setLastNumDays('30')}>30 days</li>
                        </ul>
                    </div>
                    <div className="search-by-text">
                        <input
                            type="text"
                            placeholder="Search by title"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <img alt="" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Fsearch-icon.svg?alt=media&token=82282b12-29d0-43a3-b793-06fc4de3c49d" />
                    </div>
                </div>
                <p className="events-desc">All your events are shown here.<br />Click to edit or view.</p>
                <div className="feeling-container">
                    <div className="feeling-block">
                        <span>Good ({__isEmpty(events) ? '' : (__filter(events, (event) => event.feeling === 'good')).length})</span>
                    </div>
                    <div className="feeling-block">
                        <span>Neutral ({__isEmpty(events) ? '' : (__filter(events, (event) => event.feeling === 'neutral')).length})</span>
                    </div>
                    <div className="feeling-block">
                        <span>Bad ({__isEmpty(events) ? '' : (__filter(events, (event) => event.feeling === 'bad')).length})</span>
                    </div>
                </div>
                <Table>
                    <Table.Head className="table-head">
                        {
                            mode === 'desktop' &&
                            <>
                                <Table.TextHeaderCell 
                                    flexBasis={90} 
                                    flexGrow={0} 
                                    flexShrink={0}
                                >
                                    <TextDropdownButton
                                        icon={feelingOrder === 'asc' ? 'caret-up' : 'caret-down'}
                                        onClick={sortByFeeling}
                                    >
                                        Feeling
                                    </TextDropdownButton>
                                </Table.TextHeaderCell>
                            </>
                        }
                        <Table.TextHeaderCell flexBasis={mode === 'desktop' ? 150 : null}>
                            Title
                        </Table.TextHeaderCell>
                        <Table.HeaderCell flexBasis={mode === 'desktop' ? 150 : null}>
                            <TextDropdownButton
                                icon={typeOrder === 'asc' ? 'caret-up' : 'caret-down'}
                                onClick={sortByType}
                            >
                                Type
                            </TextDropdownButton>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <TextDropdownButton
                                icon={dateOrder === 'asc' ? 'caret-up' : 'caret-down'}
                                onClick={sortByDate}
                            >
                                Date
                            </TextDropdownButton>
                        </Table.HeaderCell>
                        {
                            mode === 'desktop' &&
                            <>
                                <Table.TextHeaderCell>
                                    Time
                                </Table.TextHeaderCell>
                            </>
                        }
                    </Table.Head>
                    <Table.Body>
                        {events.map(event => (
                            <Table.Row
                                key={event.id}
                                isSelectable
                                onSelect={() => editEvent(event.id)}
                            >
                                {
                                    mode === 'desktop' &&
                                    <>
                                        <Table.Cell 
                                            className="custom-cell" 
                                            flexBasis={90} 
                                            flexGrow={0} 
                                            flexShrink={0}
                                        >
                                            <span className={`feeling-cell ${event.feeling}`}></span>
                                        </Table.Cell>
                                    </>
                                }
                                <Table.TextCell className="custom-cell" flexBasis={mode === 'desktop' ? 150 : null}>{event.title}</Table.TextCell>
                                <Table.TextCell className="custom-cell" flexBasis={mode === 'desktop' ? 150 : null}>{__startCase(event.type)}</Table.TextCell>
                                <Table.TextCell className="custom-cell">{event.date}</Table.TextCell>
                                {
                                    mode === 'desktop' &&
                                    <>
                                        <Table.TextCell className="custom-cell">{event.time}</Table.TextCell>
                                    </>
                                }
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <Link name="NewEvent">
                    <div className="new-entity-fab">
                        <Icon icon="plus" size={28} />
                    </div>
                </Link>
            </div>
        );
    }

    return (
        <div className="please-wait loading-indicator">
            <div className={`lds-ring ${isLoadingEvents ? '' : 'hide'}`}><div></div><div></div><div></div><div></div></div>
            Loading your events...
        </div>
    );
}

Events.propTypes = {
    setPageTitle: PropTypes.func.isRequired
};

export default Events;