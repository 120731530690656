import React from 'react';
import { Link } from "@curi/react-dom";

const Error = ({ setPageTitle }) => {
    setPageTitle('Coming Soon');
    
    return (
        <div className="page-wrapper maxed">
            <p>This page is coming soon.</p>
            <Link name="Home">Go Home</Link>
        </div>
    );
}

export default Error;