import React from 'react';
import { Link } from '@curi/react-dom';

import './FooterPages.scss';

const FAQ = () => {
    return (
        <div className="footer-page">
            <div className="faq">
                <h1>Frequently Asked Questions</h1>
                <p>
                    If you need help at any time please contact us at <a href="mailto:support@heygoodjob.com">support@heygoodjob.com</a>. For more information, please review our <Link name="Privacy">privacy policy</Link> and <Link name="Terms">terms of use</Link>.
                    <br /><br />
                    <b>Can anyone else see my events?</b>
                    <br /><br />
                    Events you track cannot be seen by other Good Job users. Good Job does monitor some event details in aggregate (the number of events recorded, event date, feeling, event type and attendee type) stripped of personally identifiable information (name and email). Good Job never reviews event descriptions. 
                    <br /><br />
                    <b>Can anyone else see my workplace scorecard questions?</b>
                    <br /><br />
                    Much like your events, answers to your scorecard questions are stripped of personally identifiable information (name and email) and reviewed in aggregate with other Good Job users.
                    <br /><br />
                    <b>What if I don’t want my de-identified data reviewed by Good Job?</b>
                    <br /><br />
                    At Good Job we take your data privacy seriously, that is why we will be launching an option to opt-out of sharing even de-identified information in the future.
                    <br /><br />
                    <b>How often should I track events?</b>
                    <br /><br />
                    We recommend recording how you are feeling at work at least 3 times per week - Monday, Wednesday and Friday. You can create a “check-in” by selecting the "Check-in" event type and selecting N/A under with whom. This gives you an overall glimpse into how you felt at work that week.
                    <br /><br />
                    <b>How do emojis work in the event tracker?</b>
                    <br /><br />
                    We added emojis so you could have more than one dimension to record feelings to an event. You can smash those buttons up to 100 times per emoji. This helps your remember the intensity of how you felt when you review those events later.
                    <br /><br />
                    <b>What is the Workplace Scorecard?</b>
                    <br /><br />
                    Questions designed to evaluate the health of your workplace based on Diversity and Inclusion, Pay Equity, Employee Wellbeing, Growth and Sustainability. Each company is given a score based on how well they perform in these different areas.
                    <br /><br />
                    <b>Why is the Workplace Scorecard important?</b>
                    <br /><br />
                    Because the health of your workplace has a huge impact on your ability to be happy at work. Tracking events and alongside your workplace health gives you a fuller picture of your potential workplace happiness.
                    <br /><br />
                    <b>Can I upload images to my events?</b>
                    <br /><br />
                    Not yet. We recommend uploading screenshots or images to a cloud-network like Google Drive or Dropbox, and including the link in your event description. 
                    <br /><br />
                    <b>Can I delete my account?</b>
                    <br /><br />
                    Yes, you can delete your account at any time in your account settings. Please be aware that if your account is deleted you will not be able to restore your data. 
                </p>
            </div>
        </div>
    );
};

export default FAQ;