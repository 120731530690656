import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './LinkAccountSuccess.scss';

const LinkAccountSuccess = ({ router, setPageTitle }) => {

    setPageTitle('Account Linked');

    return (
        <div className="page-wrapper maxed login">
            <p className="login-desc">Your account has been successfully linked. You may now close this page and return to Slack :)</p>
        </div>
    );
};

LinkAccountSuccess.propTypes = {
    setPageTitle: PropTypes.func.isRequired
};

export default LinkAccountSuccess;