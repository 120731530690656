import React from 'react';
import { Link } from '@curi/react-dom';

import './FooterPages.scss';

const Privacy = () => {
    return (
        <div className="footer-page">
            <div className="privacy">
                <h1>Privacy Policy</h1>
                <p>
                    Effective date: Feb 1, 2020
                    <br /><br />
                    We at Womxn Work, Inc. d/b/a Good Job (“Good Job”) know you care about how your personal information is used and shared, and we take your privacy seriously.  Please read the following to learn more about our Privacy Policy.  By using or accessing the Services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the following ways. 
                    Remember that your use of Good Job’s Services is at all times subject to the <Link name="Terms">Terms of Use</Link>, which incorporates this Privacy Policy.  Any terms we use in this Policy without defining them have the definitions given to them in the <Link name="Terms">Terms of Use</Link>.  
                    <br /><br />
                    <b>What does this Privacy Policy cover?</b>
                    <br /><br />
                    This Privacy Policy covers our treatment of personally identifiable information ("Personal Information") that we gather when you are accessing or using our Services, but not to the practices of companies we don’t own or control, or people that we don’t manage.  We gather various types of Personal Information from our users, as explained in more detail below, and we use this Personal Information internally in connection with our Services, including to personalize, provide, and improve our services, to allow you to access the Services, to contact you and allow other users to contact you, to fulfill your requests for certain products, rewards and services, and to analyze how you use the Services. We may also share some Personal Information with third parties, but only as described below. 
                    As noted in the <Link name="Terms">Terms of Use</Link>, we do not knowingly collect or solicit personal information from anyone under the age of 13. If you are under 13, please do not attempt to register for the Services or send any personal information about yourself to us. If we learn that we have collected personal information from a child under age 13, we will delete that information as quickly as possible. If you believe that a child under 13 may have provided us personal information, please contact us at <a href="mailto:support@heygoodjob.com">support@heygoodjob.com</a>. 
                    <br /><br />
                    <b>Will Good Job ever change this Privacy Policy?</b>
                    <br /><br />
                    We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time as well, but we will alert you to changes by placing a notice on www.heygoodjob.com, by sending you an email, and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them.  If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes.  Use of information we collect now is subject to the Privacy Policy in effect at the time such information is collected.
                    <br /><br />
                    <b>What Information does Good Job Collect?</b>
                    <br /><br />
                    <em>Information You Provide to Us:</em>
                    <br /><br />
                    We receive and store any information you knowingly provide to us. For example, through the registration process and/or through your account settings, we may collect Personal Information such as your name, email address, phone number, employer name, employer location, and possibly third-party account credentials (for example, your log-in credentials for LinkedIn or other third party sites. If you provide your third-party account credentials to us, you understand some content and/or information in those accounts (“Third Party Account Information”) may be transmitted into your account with us, and that Third Party Account Information transmitted to our Services is covered by this Privacy Policy; for example, your LinkedIn or Glassdoor login. Certain information may be required to register with us or to take advantage of some of our features.  
                    We will communicate with you if you’ve provided us the means to do so. For example, if you’ve given us your email address, we may send you promotional email offers on behalf of other businesses, or email you about your use of the Services. Also, we may receive a confirmation when you open an email from us. This confirmation helps us make our communications with you more interesting and improve our services.  If you do not want to receive communications from us, please let us know by emailing <a href="mailto:support@heygoodjob.com">support@heygoodjob.com</a>. 
                    <br /><br />
                    <em>Information Collected Automatically</em>
                    <br /><br />
                    Whenever you interact with our Services, we automatically receive and record information on our server logs from your browser or device, which may include your IP address, geolocation data, device identification, “cookie” information, the type of browser and/or device you’re using to access our Services, and the page or feature you requested. “Cookies” are identifiers we transfer to your browser or device that allow us to recognize your browser or device and tell us how and when pages and features in our Services are visited and by how many people.  You may be able to change the preferences on your browser or device to prevent or limit your device’s acceptance of cookies, but this may prevent you from taking advantage of some of our features.  
                    Also, if you click on a link to a third party website or service, a third party may also transmit cookies to you.  Again, this Privacy Policy does not cover the use of cookies by any third parties, and we aren’t responsible for their privacy policies and practices.  Please be aware that cookies placed by third parties may continue to track your activities online even after you have left our Services, and those third parties may not honor “Do Not Track” requests you have set using your browser or device.
                    We may use this data to customize content for you that we think you might like, based on your usage patterns.  We may also use it to improve the Services – for example, this data can tell us how often users use a particular feature of the Services, and we can use that knowledge to make the Services interesting to as many users as possible.   
                    <br /><br />
                    <em>Information Collected From Other Websites and Do Not Track Policy</em>
                    <br /><br />
                    Through cookies we place on your browser or device, we may collect information about your online activity after you leave our Services.  Just like any other usage information we collect, this information allows us to improve the Services and customize your online experience, and otherwise as described in this Privacy Policy.  Your browser may offer you a “Do Not Track” option, which allows you to signal to operators of websites and web applications and services (including behavioral advertising services) that you do not wish such operators to track certain of your online activities over time and across different websites.  Our Services do not support Do Not Track requests at this time, which means that we collect information about your online activity both while you are using the Services and after you leave our Services.
                    <br /><br />
                    <b>Will Good Job Share Any of the Personal Information it Receives?</b>
                    <br /><br />
                    We may share your Personal Information with third parties as described in this section:
                    <br /><br />
                    <b>Information that’s been de-identified.</b> We may de-identify your Personal Information so that you are not identified as an individual, and provide that information to our partners, for example, Google Analytics. However, we never disclose aggregate usage or de-identified information to a partner (or allow a partner to collect such information) in a manner that would identify you as an individual person. 
                    <br /><br />
                    <b>Agents:</b> We employ other companies and people to perform tasks on our behalf and need to share your information with them to provide products or services to you; for example, we may use a payment processing company to receive and process your credit card transactions for us. Unless we tell you differently, our agents do not have any right to use the Personal Information we share with them beyond what is necessary to assist us. [Note that an “agent” may also be considered a “partner” in certain circumstances, and would be subject to the terms of the “Information that’s been de-identified” section in that regard.]
                    <br /><br />
                    <b>User Profiles and Submissions</b> Your account privacy settings may allow you to limit the other users who can see the Personal Information in your user profile and/or what information in your user profile is visible to others.  Please remember that any content you upload to your public user profile, along with any Personal Information or content that you voluntarily disclose online in a manner other users can view (on discussion boards, in messages and chat areas, etc.) becomes publicly available, and can be collected and used by anyone.  Your user name may also be displayed to other users if and when you send messages or comments or upload images or videos through the Services and other users can contact you through messages and comments.
                    <br /><br />
                    <b>Business Transfers:</b> We may choose to buy or sell assets, and may share and/or transfer customer information in connection with the evaluation of and entry into such transactions. Also, if we (or our assets) are acquired, or if we go out of business, enter into discussions with prospective acquirers, enter bankruptcy, or go through some other change of control, Personal Information could be one of the assets transferred to, or acquired by, a third party. 
                    <br /><br />
                    <b>Protection of Good Job and Others:</b> We reserve the right to access, read, preserve, and disclose any information that we believe is necessary to comply with law or court order; enforce or apply our <Link name="Terms">Terms of Use</Link> and other agreements; or protect the rights, property, or safety of Good Job, our employees, our users, or others.  
                    <br /><br />
                    <b>Is Personal Information about me secure?</b>
                    <br /><br />
                    Your account is protected by a password for your privacy and security. You must prevent unauthorized access to your account and Personal Information by selecting and protecting your password appropriately and limiting access to your computer or device and browser by signing off after you have finished accessing your emails and your account.
                    We endeavor to protect the privacy of your account and other Personal Information we hold in our records, but unfortunately, we cannot guarantee complete security.  Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time. 
                    <br /><br />
                    <b>What Personal Information can I access?</b>
                    <br /><br />
                    Through your account settings, you may view, update, access, and, in some cases, edit or delete the following information you’ve provided to us:
                </p>
                <ul>
                    <li>name</li>
                    <li>email address</li>
                    <li>location</li>
                    <li>employer name, employer location and other employment details (for example, compensation details, promotions and job title)</li>
                    <li>notes, comments, images or ‘Events’ you have uploaded or made in your account</li>
                </ul>
                <p>
                    The information you can view, update, and delete may change as the Services change.  If you have any questions about viewing or updating information we have on file about you, please contact us at <a href="mailto:support@heygoodjob.com">support@heygoodjob.com</a>.  
                    <br /><br />
                    Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Information to third parties for such third parties’ direct marketing purposes; in order to submit such a request, please contact us at <a href="mailto:support@heygoodjob.com">support@heygoodjob.com</a>.
                    <br /><br />
                    <b>What choices do I have?</b>
                    <br /><br />
                    You can always opt not to disclose information to us, but keep in mind some information may be needed to register with us or to take advantage of some of our features.
                    <br /><br />
                    You may be able to add, update, or delete information as explained above.  When you update information, however, we may maintain a copy of the unrevised information in our records. You may delete your account by going to your account settings page and following the steps to delete your account.  Some information may remain in our records after your deletion of such information from your account. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. 
                    <br /><br />
                    <b>What if I have questions about this policy?</b>
                    <br /><br />
                    If you have any questions or concerns regarding our privacy policies, please send us a detailed message to <a href="mailto:support@heygoodjob.com">support@heygoodjob.com</a>, and we will try to resolve your concerns.
                </p>
            </div>
        </div>
    );
};

export default Privacy;