export const getCompaniesByUserId = async (userId, db) => {
    try {
        const companiesByUserSnapshot = await db.collection('employersByUsers').where('userId', '==', userId).get();
        let getCompanyDataPromises = [];
    
        companiesByUserSnapshot.forEach((doc) => {
            const companyId = doc.data().employerId;
            getCompanyDataPromises.push(db.collection('companies').where('companyId', '==', companyId).get());
        });
    
        let companies = [];
        const companiesSnapshots = await Promise.all(getCompanyDataPromises);
        companiesSnapshots.forEach((snapshot) => {
            snapshot.forEach((doc) => {
                companies.push({
                    companyId: doc.data().companyId,
                    name: doc.data().name
                });
            });
        });

        return companies;
    } catch(error) {
        throw(new Error(error));
    }
};
