import React, { useState, useEffect } from "react";
import firebase from 'firebase';
import componentQueries from 'react-component-queries'
import { Link, useResponse, useRouter } from "@curi/react-dom";

import './App.scss';

import Header from 'components/Header';
import RegistrationHeader from 'components/RegistrationHeader';
import PageHeader from 'components/PageHeader';
import Footer from 'components/Footer';
import { SideSheet, toaster } from 'evergreen-ui';

const App = ({ mode }) => {
    const router = useRouter();
    const { response } = useResponse();
    const { body:Body } = response;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showPageHeader, setShowPageHeader] = useState(false);
    const [pageHeaderTitle, setPageHeaderTitle] = useState('');
    const [currentUser, setCurrentUser] = useState(null);
    const [isAppLoaded, setIsAppLoaded] = useState(false); 
    const [activeRegState, setActiveRegState] = useState(1); 
    
    useEffect(() => {
        window.scroll(0, 0);
    }, [response.name]);

    useEffect(() => {
        if (response.name === 'Home') {
            setShowPageHeader(false);
        } else {
            setShowPageHeader(true);
        }

        const getCurrentUser = () => {
            return new Promise((resolve, reject) => {
                if (isAppLoaded) {
                    resolve(firebase.auth().currentUser);
                }

                const unsubscribe = firebase.auth().onAuthStateChanged(user => {
                    setIsAppLoaded(true);
                    unsubscribe();
                    resolve(user);
                }, reject);
            });
        };

        const triggerUserFunc = async () => {
            const currentUser = await getCurrentUser();

            if (!currentUser && response.data && response.data.isPrivatePage) {
                const url = router.url({ name: 'Home' });
                router.navigate({ url });
            }
            
            setCurrentUser(currentUser);
        };
        
        triggerUserFunc();
        
        return () => {};
    }, [response.name, response.data, router, isAppLoaded]);

    const toggleMenu = (shouldShow) => {
        if (shouldShow === undefined) {
            shouldShow = !isMenuOpen;
        }
        
        setIsMenuOpen(shouldShow);
    };

    const LinkWithCompleteAction = (routeTo, text) => (
        <Link name={routeTo} onNav={() => toggleMenu()}>{ text }</Link>
    );

    const logout = async (e) => {
        e.preventDefault();

        try {
            await firebase.auth().signOut();
            setCurrentUser(null);
            toggleMenu();

            const url = router.url({ name: 'Home' });
            router.navigate({ url });
        } catch(error) {
            console.log(error);
            toaster.danger('Something went wrong with logging out. Please try again or contact us.');
        }
    };

    if (!isAppLoaded) {
        return null;
    }

    if (response.name.includes('Admin')) {
        return (
            <Body 
                mode={mode} 
                response={response} 
                router={router} 
                setPageTitle={setPageHeaderTitle} 
                currentUser={currentUser}
            />
        );
    }

    if (response.name.includes('Register') || response.name.includes('Onboarding')) {
        return (
            <div className="app-wrapper">
                <RegistrationHeader activeRegState={activeRegState} />
                <div className="body-wrapper">
                    <Body 
                        router={router} 
                        setPageTitle={setPageHeaderTitle} 
                        setActiveRegState={setActiveRegState}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="app-wrapper">
            <Header 
                mode={mode} 
                toggleMenu={toggleMenu} 
                currentUser={currentUser}
                router={router}
                setCurrentUser={setCurrentUser}
                response={response}
            />
            <div className="body-wrapper">
                {/* <PageHeader show={showPageHeader} title={pageHeaderTitle} /> */}
                <Body 
                    mode={mode} 
                    response={response} 
                    router={router} 
                    setPageTitle={setPageHeaderTitle} 
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                />
                {
                    (!response.data || !response.data.isPrivatePage) &&
                    <Footer mode={mode} />
                }
            </div>
            {
                mode !== 'desktop' &&
                <SideSheet 
                    preventBodyScrolling 
                    isShown={isMenuOpen} 
                    onCloseComplete={() => { toggleMenu(false) } }
                    width={mode === 'desktop' ? 300 : '75vw'}
                >
                    <nav>
                        <h2>Menu</h2>
                        <ul>
                            {/* <li>{ LinkWithCompleteAction('Directory', 'Company Directory') }</li>
                            <hr /> */}
                            {
                                currentUser ? (
                                    <>
                                        <li>{ LinkWithCompleteAction('NewEvent', 'Track Event') }</li>
                                        <li>{ LinkWithCompleteAction('Timeline', 'My Timeline') }</li>
                                        <li>{ LinkWithCompleteAction('Account', 'My Account') }</li>
                                        <li><button onClick={(e) => logout(e)}>Log Out</button></li>
                                    </>
                                ) : (
                                    <>
                                        <li>{ LinkWithCompleteAction('Register', 'Sign Up') }</li>
                                        <li>{ LinkWithCompleteAction('Login', 'Log In') }</li>
                                    </>
                                )
                            }
                            <hr />
                            <li><Link name="FAQ">FAQ</Link></li>
                            <li><Link name="About">About</Link></li>
                        </ul>
                    </nav>
                </SideSheet>
            }
        </div>
    )
}

export default componentQueries(
    ({ width }) => ({ 
        mode: width > 1280 ? 'desktop' : width > 767 ? 'tablet' : 'mobile'
    }),
)(App);
