import { prepareRoutes } from '@curi/router';
import Home from './containers/Home';
import Account from './containers/Account';
import DeleteAccount from './containers/Account/Delete';
import Register from './containers/Register';
import Login from './containers/Login';
import About from './containers/FooterPages/About';
import Contact from './containers/FooterPages/Contact';
import FAQ from './containers/FooterPages/FAQ';
import Slack from './containers/FooterPages/Slack';
import Privacy from './containers/FooterPages/Privacy';
import Terms from './containers/FooterPages/Terms';
import LinkAccountLogin from './containers/LinkAccountLogin';
import LinkAccountSuccess from './containers/LinkAccountSuccess';
import InstallSlackSuccess from './containers/InstallSlackSuccess';
import Onboarding from './containers/Onboarding';
import Events from './containers/Events';
import EventForm from './containers/Events/EventForm';
import Employers from './containers/Employers';
import EmployerForm from './containers/Employers/EmployerForm';
import Admin from './containers/Admin';
import AdminDashboard from './containers/Admin/Dashboard';
import AdminUsers from './containers/Admin/Users';
import AdminUserForm from './containers/Admin/Users/UserForm';
import AdminQuestions from './containers/Admin/Questions';
import AdminQuestionForm from './containers/Admin/Questions/QuestionForm';
import AdminCompanies from './containers/Admin/Companies';
import AdminQuery from './containers/Admin/CustomQuery';
import ComingSoon from './containers/ComingSoon';
import Error from './containers/Error';

export default prepareRoutes([
    { 
        name: 'Home',
        path: '',
        respond() {
            return {
                body: Home
            };
        }
    },
    { 
        name: 'Register',
        path: 'register',
        respond() {
            return {
                body: Register
            };
        }
    },
    {
        name: 'Login',
        path: 'login',
        respond() {
            return {
                body: Login
            };
        }
    },
    {
        name: 'About',
        path: 'about',
        respond() {
            return {
                body: About
            };
        }
    },
    {
        name: 'Contact',
        path: 'contact',
        respond() {
            return {
                body: Contact
            };
        }
    },
    {
        name: 'FAQ',
        path: 'faq',
        respond() {
            return {
                body: FAQ
            };
        }
    },
    {
        name: 'Slack',
        path: 'slack',
        respond() {
            return {
                body: Slack
            };
        }
    },
    {
        name: 'Privacy',
        path: 'privacy',
        respond() {
            return {
                body: Privacy
            };
        }
    },
    {
        name: 'Terms',
        path: 'terms',
        respond() {
            return {
                body: Terms
            };
        }
    },
    {
        name: 'LinkAccountLogin',
        path: 'link_account_login/:team_id/:token/:channel',
        respond() {
            return {
                body: LinkAccountLogin,
                data: {
                    suppressLoginButtons: true
                }
            };
        }
    },
    {
        name: 'LinkAccountSuccess',
        path: 'link_account_success',
        respond() {
            return {
                body: LinkAccountSuccess,
                data: {
                    suppressLoginButtons: true
                }
            };
        }
    },
    {
        name: 'InstallSlackSuccess',
        path: 'install_slack_success',
        respond() {
            return {
                body: InstallSlackSuccess
            };
        }
    },
    { 
        name: 'Onboarding',
        path: 'onboarding',
        respond() {
            return {
                body: Onboarding
            };
        }
    },
    { 
        name: 'Account',
        path: 'account',
        respond() {
            return {
                body: Account,
                data: {
                    isPrivatePage: true
                }
            };
        },
        children: [
            {
                name: 'DeleteAccount',
                path: 'delete',
                respond() {
                    return {
                        body: DeleteAccount,
                        data: {
                            isPrivatePage: true
                        }
                    }
                }
            }
        ]
    },
    { 
        name: 'Timeline',
        path: 'timeline',
        respond() {
            return {
                body: Events,
                data: {
                    isPrivatePage: true
                }
            }
        },
        children: [
            {
                name: 'NewEvent',
                path: 'new',
                respond() {
                    return {
                        body: EventForm,
                        data: {
                            isPrivatePage: true
                        }
                    }
                }
            },
            {
                name: 'EditEvent',
                path: 'edit/:id',
                respond() {
                    return {
                        body: EventForm,
                        data: {
                            isPrivatePage: true
                        }
                    }
                }
            }
        ]
    },
    { 
        name: 'Employers',
        path: 'employers',
        respond() {
            return {
                body: Employers,
                data: {
                    isPrivatePage: true
                }
            }
        },
        children: [
            {
                name: 'NewEmployer',
                path: 'new',
                respond() {
                    return {
                        body: EmployerForm,
                        data: {
                            isPrivatePage: true
                        }
                    }
                }
            },
            {
                name: 'EditEmployer',
                path: 'edit/:id',
                respond() {
                    return {
                        body: EmployerForm,
                        data: {
                            isPrivatePage: true
                        }
                    }
                }
            }
        ]
    },
    { 
        name: 'Directory',
        path: 'directory',
        respond() {
            return {
                body: ComingSoon
            };
        }
    },
    { 
        name: 'Admin',
        path: 'admin',
        respond() {
            return {
                body: Admin,
                data: {
                    isPrivatePage: true,
                    component: AdminDashboard
                }
            }
        },
        children: [
            {
                name: 'AdminUsers',
                path: 'users',
                respond() {
                    return {
                        body: Admin,
                        data: {
                            isPrivatePage: true,
                            component: AdminUsers
                        }
                    }
                }
            },
            {
                name: 'AdminUserNew',
                path: 'users/new',
                respond() {
                    return {
                        body: Admin,
                        data: {
                            isPrivatePage: true,
                            component: AdminUserForm
                        }
                    }
                }
            },
            {
                name: 'AdminUserEdit',
                path: 'users/:id',
                respond() {
                    return {
                        body: Admin,
                        data: {
                            isPrivatePage: true,
                            component: AdminUserForm
                        }
                    }
                }
            },
            {
                name: 'AdminQuestions',
                path: 'questions',
                respond() {
                    return {
                        body: Admin,
                        data: {
                            isPrivatePage: true,
                            component: AdminQuestions
                        }
                    }
                }
            },
            {
                name: 'AdminQuestionNew',
                path: 'questions/new',
                respond() {
                    return {
                        body: Admin,
                        data: {
                            isPrivatePage: true,
                            component: AdminQuestionForm
                        }
                    }
                }
            },
            {
                name: 'AdminQuestionEdit',
                path: 'questions/:id',
                respond() {
                    return {
                        body: Admin,
                        data: {
                            isPrivatePage: true,
                            component: AdminQuestionForm
                        }
                    }
                }
            },
            {
                name: 'AdminCompanies',
                path: 'companies',
                respond() {
                    return {
                        body: Admin,
                        data: {
                            isPrivatePage: true,
                            component: AdminCompanies
                        }
                    }
                }
            },
            {
                name: 'AdminQuery',
                path: 'query',
                respond() {
                    return {
                        body: Admin,
                        data: {
                            isPrivatePage: true,
                            component: AdminQuery
                        }
                    }
                }
            }
        ]
    },
    { 
        name: 'Error', 
        path: '(.*)',
        respond() {
            return {
                body: Error
            };
        }
    }
]);
