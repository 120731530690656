import React, { useEffect } from 'react';
import { Link } from '@curi/react-dom';
import { admins } from 'utils/otherConstants';

import './Admin.scss';

const Admin = ({ router, response, currentUser }) => {
    useEffect(() => {
        if (currentUser && !admins.includes(currentUser.email)) {
            const url = router.url({ name: 'Home' });
            router.navigate({ url });
        }
    }, [router, currentUser]);
    
    if (!currentUser) {
        return null;
    }

    return (
        <div className="admin-wrapper">
            <div className="admin-nav">
                <ul>
                    <Link name="Admin">
                        <li className={`${response.name === 'Admin' ? 'active': ''}`}>Home</li>
                    </Link>
                    <Link name="AdminUsers">
                        <li className={`${response.name === 'AdminUsers' ? 'active': ''}`}>Users</li>
                    </Link>
                    <Link name="AdminQuestions">
                        <li className={`${response.name === 'AdminQuestions' ? 'active': ''}`}>Questions</li>
                    </Link>
                    <Link name="AdminCompanies">
                        <li className={`${response.name === 'AdminCompanies' ? 'active': ''}`}>Companies</li>
                    </Link>
                    <Link name="AdminQuery">
                        <li className={`${response.name === 'AdminQuery' ? 'active': ''}`}>Custom Query</li>
                    </Link>
                </ul>
            </div>
            <div className="admin-body">
                <response.data.component router={router} response={response} />
            </div>
        </div>
    );
}

export default Admin;