import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import { Link } from '@curi/react-dom';

import './Header.scss';

import { 
    Icon, 
    IconButton, 
    toaster 
} from 'evergreen-ui';

const Header = ({ 
    mode, 
    toggleMenu, 
    currentUser,
    response
}) => {
    return (
        <div className="header">
            <div className="header-left">
                <Link name="Home">
                    <img alt="Good Job" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Fgj-logo-v2-white.svg?alt=media&token=36356b9f-3a2e-4c19-9bf0-817af15a9d92" />
                </Link>
            </div>
            <div className="header-right">
                {
                    (mode === 'desktop' && currentUser) &&
                    <>
                        <Link className={`logged-in-links ${response.name === 'NewEvent' ? 'active' : ''}`} name="NewEvent">
                            Track Event
                        </Link>
                        <Link className={`logged-in-links ${response.name === 'Timeline' ? 'active' : ''}`} name="Timeline">
                            My Timeline
                        </Link>
                        <Link className="my-account-link" name="Account">
                            <img alt="My Account" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Fuser-account.svg?alt=media&token=a1e3655c-3c16-4bbf-983d-be0a8011018b" />
                        </Link>
                    </>
                }
                {
                    mode !== 'desktop' &&
                    <IconButton 
                        appearance="minimal" 
                        icon="menu" 
                        iconSize={mode === 'desktop' ? 30 : 24} 
                        onClick={() => toggleMenu()} 
                    />
                }
                {
                    (mode === 'desktop' && !currentUser && (!response.data || !response.data.suppressLoginButtons)) &&
                    <>
                        <Link name="Register" className="logged-out-action-button">
                            Sign Up
                        </Link>
                        <Link name="Login" className="logged-out-action-button red">
                            Log In
                        </Link>
                    </>
                }
            </div>
        </div>
    );
}

Header.propTypes = {
    mode: PropTypes.string.isRequired,
    toggleMenu: PropTypes.func.isRequired
};

export default Header;