/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import firebase from 'firebase';
import __isEmpty from 'lodash/isEmpty';

import '../Admin.scss';

import { 
    TextInput, 
    Select,
    Button,
    Checkbox,
    Dialog,
    toaster
} from 'evergreen-ui';

const AdminQuestionForm = ({ router, response, mode }) => {
    const db = firebase.firestore();
    const isEditMode = !__isEmpty(response.params.id);
    const [text, setText] = useState('');
    const [type, setType] = useState('');
    const [priority, setPriority] = useState(1);
    const [hasFollowUp, setHasFollowUp] = useState(false);
    const [frequency, setFrequency] = useState(0);
    const [answerChoices, setAnswerChoices] = useState([]);
    const [checkYes, setCheckYes] = useState(false);
    const [checkNo, setCheckNo] = useState(false);
    const [checkSometimes, setCheckSometimes] = useState(false);
    const [checkIDontKnow, setCheckIDontKnow] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isChildQ, setIsChildQ] = useState(sessionStorage.getItem('parentQId') !== null);
    const [parentQuestion, setParentQuestion] = useState({});
    const [totalNumOfQuestions, setTotalNumOfQuestions] = useState(-1);
    const [questionOrder, setQuestionOrder] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getQuestionsCount = async (shouldSetNextOrder) => {
            const qSnapshot = await db.collection('questions').get();
            let questionsLength = qSnapshot.size;
            setTotalNumOfQuestions(questionsLength);

            if (shouldSetNextOrder) {
                setQuestionOrder(++questionsLength);
            }
        };
        
        const getQuestionData = async (questionId) => {
            getQuestionsCount();
            const snapshot = await db.collection('questions').where('questionId', '==', questionId).get();
            const data = snapshot.docs[0].data();

            if (isEditMode) {
                setText(data.text);
                setType(data.type);
                setPriority(data.priority);
                setHasFollowUp(!__isEmpty(data.childQId));
                setFrequency(data.frequency);
                setAnswerChoices(data.choices);
                setCheckYes(data.choices.includes('yes'));
                setCheckNo(data.choices.includes('no'));
                setCheckSometimes(data.choices.includes('sometimes'));
                setCheckIDontKnow(data.choices.includes('i don\'t know'));
                setIsChildQ(!__isEmpty(data.parentQId));
                setQuestionOrder(data.order);
            } else if (isChildQ) { //will only happen for new question
                let numOfQuestions = totalNumOfQuestions;
                
                setQuestionOrder(++numOfQuestions);
                setParentQuestion(data);
                setType(data.type); //takes the type of the parent question
                setPriority(data.priority); //takes the priority of the parent question
            }
        };

        if (isEditMode) {
            getQuestionData(response.params.id);
        } else if (isChildQ) {
            getQuestionData(sessionStorage.getItem('parentQId'));
        } else {
            getQuestionsCount(true);
        }
    }, [db, isChildQ, isEditMode, response]);

    const submitQuestion = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            if (isEditMode) {
                const snapshot = await db.collection('questions').where('questionId', '==', response.params.id).get();

                await snapshot.docs[0].ref.update({
                    text: text,
                    type: type,
                    frequency: frequency,
                    priority: priority,
                    choices: answerChoices
                });
            } else {
                const cryptoRandomString = require('crypto-random-string');
                const questionId = cryptoRandomString({ length: 13 });
                const parentQId = sessionStorage.getItem('parentQId');

                let updated = {
                    questionId: questionId,
                    text: text,
                    type: type,
                    frequency: frequency,
                    priority: priority,
                    choices: answerChoices
                };

                if (parentQId !== null) {
                    updated['parentQId'] = parentQId;   
                }

                await db.collection('questions').add(updated);

                if (parentQId !== null) {
                    const snapshot = await db.collection('questions').where('questionId', '==', parentQId).get();

                    await snapshot.docs[0].ref.update({
                        childQId: questionId
                    });

                    sessionStorage.removeItem('parentQId');
                }

                if (hasFollowUp) {
                    sessionStorage.setItem('parentQId', questionId);
                    window.location.reload();
                    return;
                }
            }
            
            setIsLoading(false);
            const url = router.url({ name: 'AdminQuestions' });
            router.navigate({ url });
        } catch(error) {
            setIsLoading(false);
            console.log(error);
            toaster.danger('Something went wrong with saving your question. Please try again or contact us.');
            return;
        }
    };

    const openDeleteDialog = (e) => {
        e.preventDefault();
        setShowDeleteDialog(true);
    }

    const deleteQuestion = async () => {
        try {
            const snapshot = await db.collection('questions').where('questionId', '==', response.params.id).get();
            snapshot.docs[0].ref.delete();
            
            const url = router.url({ name: 'AdminQuestions' });
            router.navigate({ url });
        } catch(error) {
            console.log(error);
            toaster.danger('Something went wrong with deleting this question. Please try again or contact us.');
            return;
        }
    };

    const toggleAnswerChoices = (e) => {
        const choices = answerChoices;

        if (choices.includes(e.target.value)) {
            choices.splice(choices.indexOf(e.target.value), 1);
        } else {
            choices.push(e.target.value);
        }

        switch(e.target.value) {
            case 'yes':
                setCheckYes(choices.includes('yes'));
                break;
            case 'no':
                setCheckNo(choices.includes('no'));
                break;
            case 'sometimes':
                setCheckSometimes(choices.includes('sometimes'));
                break;
            case 'i don\'t know':
                setCheckIDontKnow(choices.includes('i don\'t know'));
                break;
            default:
                return;
        }
        
        setAnswerChoices(choices);
    };

    return (
        <div className="admin-form-wrapper">
            <h2 className="admin-header">{isEditMode ? 'Edit' : isChildQ ? 'New Follow-up' : 'New'} Question</h2>
            {
                isChildQ &&
                <p><b>Parent Question: {parentQuestion.text}</b><br /><br /></p>
            }
            <form onSubmit={submitQuestion}>
                <div className="form-block">
                    <p className="faux-label">Question Text (please type all punctuation)</p>
                    <TextInput 
                        type="text"
                        value={text}
                        marginRight={15}
                        marginBottom={0}
                        onChange={(e) => setText(e.target.value)}
                    />
                </div>
                <div className="form-block">
                    <p className="faux-label">{`Question Type ${!__isEmpty(parentQuestion) ? '(takes type of parent question)' : ''}`}</p>
                    <Select 
                        value={type} 
                        disabled={!__isEmpty(parentQuestion)}
                        onChange={(e) => setType(e.target.value)}
                    >
                        <option value="Time-based">Time-based</option>
                        <option value="Statement Agreement">Statement Agreement</option>
                        <option value="Demographics/Policy">Demographics/Policy</option>
                    </Select>
                </div>
                <div className="form-block">
                    <p className="faux-label">{`Priority ${!__isEmpty(parentQuestion) ? '(takes priority of parent question)' : ''}`}</p>
                    <Select 
                        value={priority} 
                        disabled={!__isEmpty(parentQuestion)}
                        onChange={(e) => setPriority(parseInt(e.target.value))}
                    >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                    </Select>
                </div>
                {
                    !isChildQ &&
                    <div className="form-block">
                        <p className="faux-label for-checks">Is there a follow-up question?</p>
                        <Select value={hasFollowUp} onChange={(e) => setHasFollowUp(e.target.value === 'true')}>
                            <option value={false}>No</option>
                            <option value={true}>Yes</option>
                        </Select>
                    </div>
                }
                <div className="form-block">
                    <p className="faux-label">How often do you want to repeat?</p>
                    <Select value={frequency} onChange={(e) => setFrequency(parseInt(e.target.value))}>
                        <option value={0}>None</option>
                        <option value={1209600}>Every 2 weeks</option>
                        <option value={2592000}>Every month</option>
                        <option value={7776000}>Every 3 months</option>
                        <option value={15552000}>Every 6 months</option>
                    </Select>
                </div>
                <div className="form-block">
                    <p className="faux-label">Choose your answer choices:</p>
                    <div className="answer-choices">
                        <Checkbox
                            label="Yes"
                            value="yes"
                            checked={checkYes}
                            onChange={(e) => toggleAnswerChoices(e)}
                        />
                        <Checkbox
                            label="No"
                            value="no"
                            checked={checkNo}
                            onChange={(e) => toggleAnswerChoices(e)}
                        />
                        <Checkbox
                            label="Sometimes"
                            value="sometimes"
                            checked={checkSometimes}
                            onChange={(e) => toggleAnswerChoices(e)}
                        />
                        <Checkbox
                            label="I Don't Know"
                            value="i don't know"
                            checked={checkIDontKnow}
                            onChange={(e) => toggleAnswerChoices(e)}
                        />
                    </div>
                </div>
                <br /><br />
                <div className="form-actions">
                    {
                        isEditMode &&
                        <div className="button-component minimal">
                            <Button 
                                appearance="minimal" 
                                intent="danger"
                                marginRight={15}
                                onClick={(e) => openDeleteDialog(e)}
                            >
                                Delete
                            </Button>
                        </div>
                    }
                    <div className="button-component custom">
                        <Button 
                            appearance="primary" 
                            isLoading={isLoading}
                            onClick={submitQuestion}
                        >
                            {isEditMode ? 'Save Question' : 'Create Question'}
                        </Button>
                    </div>
                </div>
            </form>
            <Dialog
                isShown={showDeleteDialog}
                onCloseComplete={() => setShowDeleteDialog(false)}
                confirmLabel="Yes"
                hasHeader={false}
                preventBodyScrolling
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEscapePress={false}
                minHeightContent={0}
                onConfirm={deleteQuestion}
            >
                <p>Are you sure you want to delete this question? This cannot be undone.</p>
            </Dialog>
        </div>
    );
}

export default AdminQuestionForm;