import React, { useState } from 'react';

import './FooterPages.scss';

import {
    TextInputField,
    Label,
    Textarea,
    Button,
    toaster
} from 'evergreen-ui';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    
    const submitContact = (e) => {
        e.preventDefault();
        
        if (name === '' || email === '' || subject === '' || message === '') {
            toaster.danger('Please fill out all fields.');
            return;
        }
        
        fetch(`${process.env.REACT_APP_SERVER_URL}/sendSupportEMail`, {
            method: 'POST',
            body: JSON.stringify({
                name: name,
                email: email,
                subject: subject,
                message: message
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(() => {
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');
            toaster.success('Thank you for your inquiry. We will get back to you very soon.');
        })
        .catch((error) => {
            console.error(error);
            toaster.danger('We could not send your meesage. Please try again or contact us.');
        });
    };
    
    return (
        <div className="footer-page with-bg">
            <div className="contact">
                <h1>Contact Us</h1>
                <form onSubmit={submitContact}>
                    <div className="h-group-fields">
                        <TextInputField
                            label="Name"
                            type="text"
                            required
                            value={name}
                            marginRight={20}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextInputField
                            label="Email"
                            type="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <TextInputField
                        label="Subject"
                        type="text"
                        required
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                    <Label
                        htmlFor="message-area"
                        marginBottom={4}
                        display="block"
                    >
                        Message *
                    </Label>
                    <Textarea
                        id="message-area"
                        required
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <div className="button-component custom">
                        <Button
                            appearance="primary"
                            marginTop={50}
                            onClick={submitContact}
                        >
                            Send
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Contact;