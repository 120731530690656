import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@curi/react-dom';

import './Footer.scss';

import { 
    toaster
} from 'evergreen-ui';

const Footer = ({ mode }) => {
    const [email, setEmail] = useState('');
    
    const signUpForNewsletter = (e) => {
        e.preventDefault();

        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(String(email).toLowerCase())) {
            toaster.danger('Please enter a valid email address.');
            return;
        }

        const data = {
            email: email
        };

        fetch(`${process.env.REACT_APP_SERVER_URL}/signUpForNewsletter`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(() => {
            setEmail('');
            toaster.success('Thank you for subscribing to our mailing list.');
        })
        .catch((error) => {
            console.error(error);
            toaster.danger('We could not subscribe you to the newsletter. Please try again or contact us.');
        });
    };
    
    if (mode === 'desktop' || mode === 'tablet') {
        return (
            <div className="large-footer">
                <div className="top">
                    <div className="top-column">
                        <h3>Information</h3>
                        <ul>
                            <li><Link name="FAQ">FAQ</Link></li>
                            <li><Link name="Contact">Contact Us</Link></li>
                            <li><Link name="About">About Us</Link></li>
                            <li><Link name="Slack">Add to Slack</Link></li>
                            <li><Link name="Terms">Terms &amp; Conditions</Link></li>
                            <li><Link name="Privacy">Privacy Policy</Link></li>
                            <li><a href="https://www.heygoodjob.com/copyright" target="_blank" rel="noopener noreferrer">Copyright</a></li>
                        </ul>
                    </div>
                    <div className="top-column">
                        <div className="top-column-section">
                            <h3>Stay Connected</h3>
                            <div className="top-social-links">
                                <a href="https://www.linkedin.com/company/heygoodjob/" target="_blank" rel="noopener noreferrer">
                                    <img alt="LinkedIn" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Flg-icon-linkedin.svg?alt=media&token=738f66ff-a776-460c-a4db-6f708a0ed4cd" />
                                </a>
                                <a href="https://www.facebook.com/sayheygoodjob/" target="_blank" rel="noopener noreferrer">
                                    <img alt="Facebook" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Flg-icon-facebook.svg?alt=media&token=90218a9e-158e-4cfd-81bf-8c7130652551" />
                                </a>
                                <a href="https://www.instagram.com/sayheygoodjob/" target="_blank" rel="noopener noreferrer">
                                    <img alt="Instagram" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Flg-icon-instagram.svg?alt=media&token=374835c9-8866-4c30-a19d-4e08799132c5" />
                                </a>
                                <a href="https://twitter.com/sayheygoodjob" target="_blank" rel="noopener noreferrer">
                                    <img alt="Twitter" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Flg-icon-twitter.svg?alt=media&token=49a7e887-094f-40b5-bec8-16486f386395" />
                                </a>
                                <a className="tiktok-icon" href="https://vm.tiktok.com/bYNBjv/" target="_blank" rel="noopener noreferrer">
                                    <img alt="Tik Tok" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Ftiktok-icon.svg?alt=media&token=cb468551-2135-41f7-856f-210ba3d65ef0" />
                                </a>
                            </div>
                        </div>
                        <div className="top-column-section">
                            <h3>Newsletter Subscription</h3>
                            <div className="footer-subscribe">
                                <input 
                                    className="subscribe-email" 
                                    type="email" 
                                    placeholder="Enter email address" 
                                    value={email} 
                                    onChange={(e) => setEmail(e.target.value)} 
                                />
                                <button className="footer-sub-button" onClick={signUpForNewsletter}>Subscribe</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <p>&copy;{new Date().getFullYear()} Womxn Work, Inc. All Rights Reserved.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="mobile-footer">
            <div className="vert-top">
                <Link name="FAQ">FAQ</Link>
                <Link name="Contact">Contact Us</Link>
                <Link name="About">About</Link>
                <Link name="Slack">Slack</Link>
                <Link name="Terms">Terms</Link>
                <Link name="Privacy">Privacy</Link>
            </div>
            <div className="vert-center">
                <a href="https://www.linkedin.com/company/heygoodjob/" target="_blank" rel="noopener noreferrer">
                    <img alt="LinkedIn" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Flinkedin.svg?alt=media&token=42c077b7-b430-4387-a1b1-5e314113ee25" />
                </a>
                <a href="https://www.facebook.com/sayheygoodjob/" target="_blank" rel="noopener noreferrer">
                    <img alt="Facebook" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Ffacebook.svg?alt=media&token=9d8da0c5-111b-4e84-8810-aef988e5ae00" />
                </a>
                <a href="https://www.instagram.com/sayheygoodjob/" target="_blank" rel="noopener noreferrer">
                    <img alt="Instagram" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Finstagram.svg?alt=media&token=19f84177-ea55-49c9-bb2c-827058651963" />
                </a>
                <a href="https://twitter.com/sayheygoodjob" target="_blank" rel="noopener noreferrer">
                    <img alt="Twitter" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Ftwitter.svg?alt=media&token=456d78bd-47ca-4477-a822-8022b7210abd" />
                </a>
                <a className="tiktok-icon" href="https://vm.tiktok.com/bYNBjv/" target="_blank" rel="noopener noreferrer">
                    <img alt="Tik Tok" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Ftiktok-icon.svg?alt=media&token=cb468551-2135-41f7-856f-210ba3d65ef0" />
                </a>
            </div>
            <p>&copy;{new Date().getFullYear()} Womxn Work, Inc. All Rights Reserved.</p>
        </div>
    );
}

Footer.propTypes = {
    mode: PropTypes.string.isRequired,
};

export default Footer;