import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './InstallSlackSuccess.scss';

const InstallSlackSuccess = ({ router, setPageTitle }) => {

    setPageTitle('Slack App Installed');

    return (
        <div className="page-wrapper maxed login">
            <p className="login-desc">The Good Job bot has been successfully installed in your Slack workspace.
                You may now close this page and return to Slack. You and any other users in your workspace
                may now open a direct message session with the Good Job bot to check in.</p>
        </div>
    );
};

InstallSlackSuccess.propTypes = {
    setPageTitle: PropTypes.func.isRequired
};

export default InstallSlackSuccess;