import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import moment from 'moment';
import { getCompaniesByUserId } from 'utils/api';
import __isEmpty from 'lodash/isEmpty';

import './Events.scss';

import { 
    TextInputField, 
    Select,
    Button,
    Textarea,
    Dialog,
    toaster
} from 'evergreen-ui';

const EventForm = ({ router, response, setPageTitle, currentUser, mode }) => {
    const db = firebase.firestore();
    const isEditMode = !__isEmpty(response.params.id);
    const currDate = moment().format('YYYY-MM-DD');
    const currTime = moment().format('H:mm');
    const [userId, setUserId] = useState('');
    const [eventTitle, setEventTitle] = useState('');
    const [eventDate, setEventDate] = useState(currDate);
    const [eventTime, setEventTime] = useState(currTime);
    const [eventCompany, setEventCompany] = useState('');
    const [feeling, setFeeling] = useState('good');
    const [eventType, setEventType] = useState('check-in');
    const [withWhom, setWithWhom] = useState([]);
    const [notes, setNotes] = useState('');
    const [userCompanies, setUserCompanies] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [emojis, setEmojis] = useState([]);

    setPageTitle(isEditMode ? 'Edit Event' : 'Track New Event');

    useEffect(() => {
        const getCompanies = async () => {
            try {
                if (currentUser) {
                    const snapshot = await db.collection('users').where('email', '==', currentUser.email).get();
                    const data = snapshot.docs[0].data();

                    if (!data.profileId) {
                        throw(new Error('Couldn\'t retrieve user\'s id'));
                    }
                    
                    const companies = await getCompaniesByUserId(data.profileId, db);
                    setUserId(data.profileId);

                    if (companies.length === 1) {
                        setEventCompany(companies[0].companyId);
                    } else {
                        setUserCompanies(companies);
                    }

                    if (!isEditMode) {
                        setEventCompany(companies[0].companyId);
                    }
                }
            } catch(error) {
                console.log(error);
                toaster.danger('Something went wrong retrieving your data. Please refresh the page or contact us.');
            }
        };
        
        const getEventData = async (eventId) => {
            const snapshot = await db.collection('events').where('eventId', '==', eventId).get();
            const data = snapshot.docs[0].data();

            setEventTitle(data.title);
            setEventDate(moment(data.eventDate.seconds * 1000).format('YYYY-MM-DD'));
            setEventTime(moment(data.eventDate.seconds * 1000).format('H:mm'));
            setEventCompany(data.companyId);
            setFeeling(data.feeling);
            setEventType(data.type);
            setWithWhom(data.audience);
            setNotes(data.notes);
            setEmojis(data.emojis || []);
        };

        getCompanies();

        if (isEditMode) {
            getEventData(response.params.id);
        }
    }, [currentUser, db, isEditMode, response]);

    const submitEvent = async (e) => {
        e.preventDefault();

        try {
            const rightNow = firebase.firestore.Timestamp.fromDate(moment().toDate());
            const eventDateTime = firebase.firestore.Timestamp.fromDate(moment(`${eventDate}T${eventTime}`).toDate());

            if (isEditMode) {
                const eventId = response.params.id;
                const snapshot = await db.collection('events').where('eventId', '==', eventId).get();

                await snapshot.docs[0].ref.update({
                    companyId: eventCompany,
                    title: eventTitle,
                    feeling: feeling,
                    type: eventType,
                    audience: withWhom,
                    notes: notes,
                    modified: rightNow,
                    eventDate: eventDateTime,
                    emojis: emojis
                });
            } else {
                const cryptoRandomString = require('crypto-random-string');
                const eventId = cryptoRandomString({ length: 13 });

                await db.collection('events').add({
                    eventId: eventId,
                    title: eventTitle,
                    userId: userId,
                    companyId: eventCompany,
                    feeling: feeling,
                    type: eventType,
                    audience: withWhom,
                    modified: null,
                    notes: notes,
                    created: rightNow,
                    eventDate: eventDateTime,
                    emojis: emojis
                });
            }

            const url = router.url({ name: 'Timeline' });
            router.navigate({ url });
        } catch(error) {
            console.log(error);
            toaster.danger('Something went wrong with saving event info. Please try again or contact us.');
            return;
        }
    };

    const saveWithWhom = (value) => {
        let currWithWhom = withWhom;

        //adding "alone" or "1:1" should act as radio buttons
        if (value === 'alone' && !currWithWhom.includes('alone')) {
            setWithWhom(['alone']);
            return;
        } else if (value === 'oneOnOne' && !currWithWhom.includes('oneOnOne')) {
            setWithWhom(['oneOnOne']);
            return;
        }
        
        //always remove "alone" and 1:1
        const aloneIndex = currWithWhom.indexOf('alone');
        if (aloneIndex > -1) {
            currWithWhom.splice(aloneIndex);
        }
        
        const oneIndex = currWithWhom.indexOf('oneOnOne');
        if (oneIndex > -1) {
            currWithWhom.splice(oneIndex);
        }

        if (currWithWhom.includes(value)) {
            const index = currWithWhom.indexOf(value);
            currWithWhom.splice(index);
        } else {
            currWithWhom.push(value);
        }

        setWithWhom([...currWithWhom]);
    };

    const openDeleteDialog = (e) => {
        e.preventDefault();
        setShowDeleteDialog(true);
    };

    const deleteEvent = async () => {
        try {
            const snapshot = await db.collection('events').where('eventId', '==', response.params.id).get();
            snapshot.docs[0].ref.delete();
            
            const url = router.url({ name: 'Timeline' });
            router.navigate({ url });
        } catch(error) {
            console.log(error);
            toaster.danger('Something went wrong with deleting this event. Please try again or contact us.');
            return;
        }
    };

    const saveEmoji = (value) => {
        const tempEmojis = emojis;

        if (tempEmojis.length === 100) {
            alert('Max is 100 emojis');
            return;
        }

        tempEmojis.push(value);
        setEmojis([...tempEmojis]);
    };

    const countItem = (array, value) => {
        if (!array || !array.length) {
            return;
        }
        
        let count = 0;

        for (let i = 0; i < array.length; i++) {
            if (array[i] === value) {
                count++;
            }
        }

        return count > 0 ? count : '';
    };

    return (
        <div className="page-wrapper maxed events">
            {
                !isEditMode &&
                <p className="events-desc">
                    Note the details of your event. The more detailed you are, 
                    the better you will be able to recall it later.
                </p>
            }
            <form onSubmit={submitEvent}>
                <h2 className="faux-label">How are you feeling today?</h2>
                <div className="feeling-options">
                    <div className={`option good ${feeling === 'good' ? 'selected' : ''}`} onClick={() => setFeeling('good')}>Good</div>
                    <div className={`option neutral ${feeling === 'neutral' ? 'selected' : ''}`} onClick={() => setFeeling('neutral')}>Neutral</div>
                    <div className={`option bad ${feeling === 'bad' ? 'selected' : ''}`} onClick={() => setFeeling('bad')}>Bad</div>
                </div>
                <br /><br />
                <p className="faux-label">When did this event happen? *</p>
                <div className="h-group-fields">
                    <TextInputField 
                        label="Date"
                        type="date"
                        max={currDate}
                        value={eventDate}
                        marginRight={15}
                        marginBottom={0}
                        pattern="\d{4}-\d{2}-\d{2}"
                        onChange={(e) => setEventDate(e.target.value)}
                    />
                    <TextInputField 
                        label="Time"
                        type="time"
                        value={eventTime}
                        marginBottom={0}
                        onChange={(e) => setEventTime(e.target.value)}
                    />
                </div>
                {
                    !__isEmpty(userCompanies) &&
                    <>
                        <p className="faux-label">For what company? *</p>
                        <Select 
                            value={eventCompany}
                            onChange={(e) => setEventCompany(e.target.value)}
                        >
                            {
                                userCompanies.map((company, i) => (
                                    <option key={company.companyId} value={company.companyId}>
                                        {company.name}
                                    </option>
                                ))
                            }
                        </Select>
                    </>
                }
                <p className="faux-label">What type of event? *</p>
                <div className="select-options">
                    <div className={`option ${eventType === 'check-in' ? 'selected' : ''}`} onClick={() => setEventType('check-in')}>Check-in</div>
                    <div className={`option ${eventType === 'communication' ? 'selected' : ''}`} onClick={() => setEventType('communication')}>Communication</div>
                    <div className={`option ${eventType === 'interview' ? 'selected' : ''}`} onClick={() => setEventType('interview')}>Interview</div>
                    <div className={`option ${eventType === 'meeting' ? 'selected' : ''}`} onClick={() => setEventType('meeting')}>Meeting</div>
                    <div className={`option ${eventType === 'other' ? 'selected' : ''}`} onClick={() => setEventType('other')}>Other</div>
                    <div className={`option ${eventType === 'review' ? 'selected' : ''}`} onClick={() => setEventType('review')}>Review</div>
                    <div className={`option ${eventType === 'presentation' ? 'selected' : ''}`} onClick={() => setEventType('presentation')}>Presentation</div>
                </div>
                <p className="faux-label no-margin">With whom? *</p>
                <span className="sub-faux-label">Select all that apply</span>
                <div className="select-options">
                    <div className={`option ${withWhom.includes('alone') ? 'selected' : ''}`} onClick={() => saveWithWhom('alone')}>N/A</div>
                    <div className={`option ${withWhom.includes('oneOnOne') ? 'selected' : ''}`} onClick={() => saveWithWhom('oneOnOne')}>1:1</div>
                    <div className={`option ${withWhom.includes('colleague') ? 'selected' : ''}`} onClick={() => saveWithWhom('colleague')}>Colleague</div>
                    <div className={`option ${withWhom.includes('report') ? 'selected' : ''}`} onClick={() => saveWithWhom('report')}>Direct Report</div>
                    <div className={`option ${withWhom.includes('external') ? 'selected' : ''}`} onClick={() => saveWithWhom('external')}>External Group</div>
                    <div className={`option ${withWhom.includes('hr') ? 'selected' : ''}`} onClick={() => saveWithWhom('hr')}>HR</div>
                    <div className={`option ${withWhom.includes('internal') ? 'selected' : ''}`} onClick={() => saveWithWhom('internal')}>Internal Group</div>
                    <div className={`option ${withWhom.includes('candidate') ? 'selected' : ''}`} onClick={() => saveWithWhom('candidate')}>Candidate</div>
                    <div className={`option ${withWhom.includes('manager') ? 'selected' : ''}`} onClick={() => saveWithWhom('manager')}>Manager</div>
                    <div className={`option ${withWhom.includes('other') ? 'selected' : ''}`} onClick={() => saveWithWhom('other')}>Other</div>
                </div>
                <p className="faux-label">With emojis, let us know how you are dealing with the situation.</p>
                <span className="sub-faux-label">Select all that apply (Max 100)</span>
                <div className="emoji-select">
                    <div className={`emoji-option ${(countItem(emojis, '🙌')) > 0 ? 'selected' : ''}`} onClick={() => saveEmoji('🙌')}>
                        <span role="img" aria-label="celebration">🙌</span>
                        <span className="emoji-count">{(countItem(emojis, '🙌'))}</span>
                    </div>
                    <div className={`emoji-option ${(countItem(emojis, '😐')) > 0 ? 'selected' : ''}`} onClick={() => saveEmoji('😐')}>
                        <span role="img" aria-label="meh">😐</span>
                        <span className="emoji-count">{(countItem(emojis, '😐'))}</span>
                    </div>
                    <div className={`emoji-option ${(countItem(emojis, '😠')) > 0 ? 'selected' : ''}`} onClick={() => saveEmoji('😠')}>
                        <span role="img" aria-label="angry">😠</span>
                        <span className="emoji-count">{(countItem(emojis, '😠'))}</span>
                    </div>
                    <div className={`emoji-option ${(countItem(emojis, '💩')) > 0 ? 'selected' : ''}`} onClick={() => saveEmoji('💩')}>
                        <span role="img" aria-label="poo">💩</span>
                        <span className="emoji-count">{(countItem(emojis, '💩'))}</span>
                    </div>
                    <div className={`emoji-option ${(countItem(emojis, '😭')) > 0 ? 'selected' : ''}`} onClick={() => saveEmoji('😭')}>
                        <span role="img" aria-label="crying">😭</span>
                        <span className="emoji-count">{(countItem(emojis, '😭'))}</span>
                    </div>
                    <div className={`emoji-option ${(countItem(emojis, '🤗')) > 0 ? 'selected' : ''}`} onClick={() => saveEmoji('🤗')}>
                        <span role="img" aria-label="hugs">🤗</span>
                        <span className="emoji-count">{(countItem(emojis, '🤗'))}</span>
                    </div>
                    <div className={`emoji-option ${(countItem(emojis, '💪')) > 0 ? 'selected' : ''}`} onClick={() => saveEmoji('💪')}>
                        <span role="img" aria-label="strong">💪</span>
                        <span className="emoji-count">{(countItem(emojis, '💪'))}</span>
                    </div>
                    <div className={`emoji-option ${(countItem(emojis, '🔥')) > 0 ? 'selected' : ''}`} onClick={() => saveEmoji('🔥')}>
                        <span role="img" aria-label="lit">🔥</span>
                        <span className="emoji-count">{(countItem(emojis, '🔥'))}</span>
                    </div>
                </div>
                <div className="reset-emojis"><span onClick={() => setEmojis([])}>Reset Emojis</span></div>
                <p className="faux-label">Add a short name to remember this event. * (max 60 characters)</p>
                <TextInputField 
                    label=""
                    type="text"
                    maxLength={60}
                    value={eventTitle}
                    marginBottom={0}
                    onChange={(e) => setEventTitle(e.target.value)}
                />
                <p className="faux-label">Any additional details?</p>
                <Textarea
                    name="notes"
                    placeholder="Add notes here..."
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
                <br /><br />
                <div className="form-actions">
                    {
                        isEditMode &&
                        <div className="button-component minimal">
                            <Button 
                                appearance="minimal" 
                                intent="danger"
                                marginRight={15}
                                onClick={(e) => openDeleteDialog(e)}
                            >
                                Delete
                            </Button>
                        </div>
                    }
                    <div className="button-component custom">
                        <Button 
                            appearance="primary" 
                            onClick={submitEvent}
                            disabled={
                                __isEmpty(eventTitle) ||
                                __isEmpty(eventDate) ||
                                __isEmpty(eventTime) ||
                                __isEmpty(eventCompany) ||
                                __isEmpty(feeling) ||
                                __isEmpty(eventType) ||
                                __isEmpty(withWhom)
                            }
                        >
                            {isEditMode ? 'Save Event' : 'Create Event'}
                        </Button>
                    </div>
                </div>
            </form>
            <Dialog
                isShown={showDeleteDialog}
                onCloseComplete={() => setShowDeleteDialog(false)}
                confirmLabel="Yes"
                hasHeader={false}
                preventBodyScrolling
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEscapePress={false}
                topOffset={mode === 'desktop' ? '12vmin' : '50vmin'}
                minHeightContent={0}
                onConfirm={deleteEvent}
            >
                <p>Are you sure you want to delete this event? This cannot be undone.</p>
            </Dialog>
        </div>
    );
}

EventForm.propTypes = {
    setPageTitle: PropTypes.func.isRequired
};

export default EventForm;