import React from 'react';
import { Link } from "@curi/react-dom";

const Error = ({ setPageTitle }) => {
    setPageTitle('Page Not Found');
    
    return (
        <div className="page-wrapper maxed">
            <p>Oops! You have encountered an error. Please click the link below to return home.</p>
            <Link name="Home">Go Home</Link>
        </div>
    );
}

export default Error;