/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import firebase from 'firebase';
import __isEmpty from 'lodash/isEmpty';
import { admins } from 'utils/otherConstants';

import '../Admin.scss';

import { 
    TextInput, 
    Button,
    Dialog,
    toaster
} from 'evergreen-ui';

const AdminUserForm = ({ router, response, mode }) => {
    const db = firebase.firestore();
    const isEditMode = !__isEmpty(response.params.id);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    useEffect(() => {
        const getUserData = async (userId) => {
            const snapshot = await db.collection('users').where('profileId', '==', userId).get();
            const data = snapshot.docs[0].data();

            if (isEditMode) {
                setFirstName(data.firstName);
                setLastName(data.lastName);
                setEmail(data.email);
            }
        };

        if (isEditMode) {
            getUserData(response.params.id);
        }
    }, [db, isEditMode, response]);

    const submitUser = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            if (isEditMode) {
                const snapshot = await db.collection('users').where('profileId', '==', response.params.id).get();

                await snapshot.docs[0].ref.update({
                    firstName: firstName,
                    lastName: lastName,
                    email: email
                });
            } else {
                const cryptoRandomString = require('crypto-random-string');
                const profileId = cryptoRandomString({ length: 13 });

                let updated = {
                    profileId: profileId,
                    firstName: firstName,
                    lastName: lastName,
                    email: email
                };

                await db.collection('users').add(updated);
            }
            
            setIsLoading(false);
            const url = router.url({ name: 'AdminUsers' });
            router.navigate({ url });
        } catch(error) {
            setIsLoading(false);
            console.log(error);
            toaster.danger('Something went wrong with saving the new user. Please try again or contact us.');
            return;
        }
    };

    const openDeleteDialog = (e) => {
        e.preventDefault();
        setShowDeleteDialog(true);
    }

    const deleteUser = async () => {
        try {
            const empByUsersSnap = await db.collection('employersByUsers').where('userId', '==', response.params.id).get();
            empByUsersSnap.forEach((employer) => {
                employer.ref.delete();
            });

            const eventsSnap = await db.collection('events').where('userId', '==', response.params.id).get();
            eventsSnap.forEach((event) => {
                event.ref.delete();
            });

            const answersSnap = await db.collection('questionsAnswered').where('userId', '==', response.params.id).get();
            answersSnap.forEach((answer) => {
                answer.ref.delete();
            });
            
            const snapshot = await db.collection('users').where('profileId', '==', response.params.id).get();
            snapshot.docs[0].ref.delete();
            
            const url = router.url({ name: 'AdminQuestions' });
            router.navigate({ url });
        } catch(error) {
            console.log(error);
            toaster.danger('Something went wrong with deleting this question. Please try again or contact us.');
            return;
        }
    };

    return (
        <div className="admin-form-wrapper">
            <h2 className="admin-header">{isEditMode ? 'Edit' : 'New'} User</h2>
            <form onSubmit={submitUser}>
                <div className="form-block">
                    <p className="faux-label">First Name</p>
                    <TextInput 
                        type="text"
                        value={firstName}
                        marginRight={15}
                        marginBottom={0}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </div>
                <div className="form-block">
                    <p className="faux-label">Last Name</p>
                    <TextInput 
                        type="text"
                        value={lastName}
                        marginRight={15}
                        marginBottom={0}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </div>
                <div className="form-block">
                    <p className="faux-label">Email</p>
                    <TextInput 
                        type="text"
                        value={email}
                        marginRight={15}
                        marginBottom={0}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <br /><br />
                <div className="form-actions">
                    {
                        isEditMode &&
                        <div className="button-component minimal">
                            <Button 
                                appearance="minimal" 
                                intent="danger"
                                marginRight={15}
                                disabled={admins.includes(email)}
                                onClick={(e) => openDeleteDialog(e)}
                            >
                                Delete
                            </Button>
                        </div>
                    }
                    <div className="button-component custom">
                        <Button 
                            appearance="primary" 
                            isLoading={isLoading}
                            onClick={submitUser}
                        >
                            {isEditMode ? 'Save User' : 'Create User'}
                        </Button>
                    </div>
                </div>
            </form>
            <Dialog
                isShown={showDeleteDialog}
                onCloseComplete={() => setShowDeleteDialog(false)}
                confirmLabel="Yes"
                hasHeader={false}
                preventBodyScrolling
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEscapePress={false}
                minHeightContent={0}
                onConfirm={deleteUser}
            >
                <p>Are you sure you want to delete this user? This cannot be undone.</p>
            </Dialog>
        </div>
    );
}

export default AdminUserForm;