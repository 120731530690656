import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import moment from 'moment';
import __isEmpty from 'lodash/isEmpty';

import './Employers.scss';

import Autocomplete from 'react-autocomplete';
import { 
    TextInputField, 
    SelectField,
    Button,
    Checkbox,
    Dialog,
    toaster
} from 'evergreen-ui';

const EmployerForm = ({ router, response, setPageTitle, currentUser, mode }) => {
    const db = firebase.firestore();
    const isEditMode = !__isEmpty(response.params.id);
    const currDate = moment().format('YYYY-MM-DD');
    const [userId, setUserId] = useState('');
    const [employerId, setEmployerId] = useState('');
    const [employerName, setEmployerName] = useState('');
    const [employerStartDate, setEmployerStartDate] = useState(currDate);
    const [employerEndDate, setEmployerEndDate] = useState('');
    const [positions, setPositions] = useState([
        {title: '', expLevel: '', wageAmt: 0, wageType: '', startDate: '', endDate: null}
    ]);
    const [isPresentJob, setIsPresentJob] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    //for autocomplete
    const [employerNamesList, setEmployerNamesList] = useState([]);
    const [selectedEmployerName, setSelectedEmployerName] = useState('');
    const [employerDBList, setEmployerDBList] = useState([]);

    setPageTitle(isEditMode ? 'Edit Employer' : 'Add New Employer');

    useEffect(() => {
        const getEmployerData = async (empByUserId) => {
            const snapshot = await db.collection('employersByUsers').where('empByUserId', '==', empByUserId).get();
            const data = snapshot.docs[0].data();
            let positions = data.positions;

            positions.forEach((position) => {
                position.startDate = moment(position.startDate.seconds * 1000).format('YYYY-MM-DD');
                
                if (position.endDate !== null) {
                    position.endDate = moment(position.endDate.seconds * 1000).format('YYYY-MM-DD');
                }
            });

            setPositions(positions);
            setEmployerStartDate(moment(data.startDate.seconds * 1000).format('YYYY-MM-DD'));
            
            if (data.endDate) {
                setEmployerEndDate(moment(data.endDate.seconds * 1000).format('YYYY-MM-DD'));
            } else {
                setIsPresentJob(true);
            }
            
            const companySnapshot = await db.collection('companies').where('companyId', '==', data.employerId).get();
            const companyData = companySnapshot.docs[0].data();
            setEmployerName(companyData.name);
            setEmployerId(companyData.companyId);
        };

        const getUserData = async () => {
            const snapshot = await db.collection('users').where('email', '==', currentUser.email).get();
            const data = snapshot.docs[0].data();
            setUserId(data.profileId);
        };

        if (currentUser) {
            getUserData();
        }
        
        if (isEditMode) {
            getEmployerData(response.params.id);
        }
    }, [currentUser, db, isEditMode, response]);

    const submitEmployer = async (e) => {
        e.preventDefault();

        try {
            if (isEditMode) {
                const empByUserId = response.params.id;
                const snapshot = await db.collection('employersByUsers').where('empByUserId', '==', empByUserId).get();

                positions.forEach((position) => {
                    position.startDate = firebase.firestore.Timestamp.fromDate(moment(position.startDate).toDate());

                    if (position.endDate) {
                        position.endDate = firebase.firestore.Timestamp.fromDate(moment(position.endDate).toDate());
                    }
                });

                await snapshot.docs[0].ref.update({
                    positions: positions,
                    startDate: firebase.firestore.Timestamp.fromDate(moment(employerStartDate).toDate()),
                    endDate: __isEmpty(employerEndDate) ? null : firebase.firestore.Timestamp.fromDate(moment(employerEndDate).toDate())
                });
            } else {
                const companyToSave = employerDBList.find((company) => {
                    return company.name === selectedEmployerName;
                });
    
                const cryptoRandomString = require('crypto-random-string');
                let companyId = '';
                let companySnapshot = null;
                
                if (companyToSave) {
                    companySnapshot = await db.collection('companies').where('domain', '==', companyToSave.domain).get();
                }
                
                if (!companySnapshot) {
                    companyId = cryptoRandomString({ length: 13 });

                    await db.collection('companies').add({
                        companyId: companyId,
                        name: selectedEmployerName,
                        domain: '',
                        logo: ''
                    });
                } else if (companySnapshot.empty) {
                    companyId = cryptoRandomString({ length: 13 });

                    await db.collection('companies').add({
                        companyId: companyId,
                        name: companyToSave.name,
                        domain: companyToSave.domain,
                        logo: companyToSave.logo
                    });
                } else {
                    //should be only one since domains are unique
                    companyId = companySnapshot.docs[0].data().companyId;
                }

                //we need to make sure all other employers have updated endDates since we are
                //adding a new employer - there should only be one
                const previousEmployers = await db.collection('employersByUsers')
                    .where('userId', '==', userId)
                    .where('endDate', '==', null)
                    .get();

                if (!previousEmployers.empty) {
                    const previousEmployersRef = previousEmployers.docs[0].ref;
                    const previousEmployersData = previousEmployers.docs[0].data();
                    previousEmployersData.endDate = firebase.firestore.Timestamp.fromDate(moment(currDate).toDate());
                    await previousEmployersRef.update(previousEmployersData);
                }

                const empByUserId = cryptoRandomString({ length: 13 });
                await db.collection('employersByUsers').add({
                    empByUserId: empByUserId,
                    employerId: companyId,
                    positions: positions,
                    startDate: firebase.firestore.Timestamp.fromDate(moment(employerStartDate).toDate()),
                    endDate: __isEmpty(employerEndDate) ? null : firebase.firestore.Timestamp.fromDate(moment(employerEndDate).toDate()),
                    userId: userId
                });
            }

            const url = router.url({ name: 'Employers' });
            router.navigate({ url });
        } catch(error) {
            console.log(error);
            toaster.danger('Something went wrong with saving employer info. Please try again or contact us.');
            return;
        }
    };

    const getEmployers = async (e) => {
        setSelectedEmployerName(e.target.value)
        const employer = e.target.value;
        if (employer.length > 1) {
            const response = await fetch(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${employer}`)
            const json = await response.json();

            let employerNames = [];
            for (let i = 0; i < json.length; i++) {
                employerNames.push(json[i].name);
            }
            //for the options in the autocomplete
            setEmployerNamesList(employerNames);

            //to use in the db
            setEmployerDBList(json);
        }
    };

    const openDeleteDialog = (e) => {
        e.preventDefault();
        setShowDeleteDialog(true);
    }

    const selectEmployer = (employerName) => {
        setSelectedEmployerName(employerName);
        setEmployerNamesList([]);
    };

    const deleteEmployer = async () => {
        try {
            const snapshot = await db.collection('employersByUsers').where('empByUserId', '==', response.params.id).get();
            snapshot.docs[0].ref.delete();
            
            const url = router.url({ name: 'Employers' });
            router.navigate({ url });
        } catch(error) {
            console.log(error);
            toaster.danger('Something went wrong with deleting this employer. Please try again or contact us.');
            return;
        }

    };

    const updatePosition = (index, key) => (e) => {
        let newPositions = [...positions];
        newPositions[index][key] = e.target.value;
        setPositions(newPositions);
    };

    const addNewPosition = () => {
        let newPositions = [...positions];
        newPositions.forEach((position) => {
            //we always want the latest position to have an endDate of null
            if (position.endDate === null) {
                position.endDate = currDate;
            }
        });
        newPositions.push({title: '', expLevel: '', wageAmt: 0, wageType: '', startDate: '', endDate: null});
        setPositions(newPositions);
    };

    return (
        <div className="page-wrapper maxed employers">
            {
                !isEditMode &&
                <p className="employers-desc">
                    Below you can add a new employer. Remember, the more detailed you are, the better you will 
                    be able to track your growth over time.
                </p>
            }
            <form onSubmit={submitEmployer}>
                {
                    isEditMode ? (
                        <p className="company-name">Company: {employerName}</p>
                    ) : (
                        <>
                            <p className="faux-label">Employer</p>
                            <div className="faux-evergreen-autocomplete-styles">
                                <Autocomplete
                                    getItemValue={(item) => item}
                                    items={employerNamesList}
                                    open={employerNamesList.length > 0}
                                    renderItem={(item, isHighlighted) =>
                                        <div 
                                            key={item.toString()} 
                                            className={`autocomplete-option ${isHighlighted ? 'highlight' : ''}`}
                                        >
                                            {item}
                                        </div>
                                    }
                                    value={selectedEmployerName}
                                    onChange={(e) => getEmployers(e)}
                                    inputProps={{
                                        onBlur: () => setEmployerNamesList([])
                                    }}
                                    onSelect={(changedEmployer) => selectEmployer(changedEmployer)}
                                />
                            </div>
                        </>
                    )
                }
                <p className="faux-label">When did you start this job?</p>
                <TextInputField 
                    label="Date"
                    type="date"
                    max={currDate}
                    value={employerStartDate}
                    pattern="\d{4}-\d{2}-\d{2}"
                    marginBottom={0}
                    onChange={(e) => setEmployerStartDate(e.target.value)}
                />
                <p className="faux-label">When did you end this job?</p>
                <TextInputField 
                    label="Date"
                    type="date"
                    max={currDate}
                    value={employerEndDate}
                    pattern="\d{4}-\d{2}-\d{2}"
                    marginBottom={0}
                    disabled={isPresentJob}
                    onChange={(e) => setEmployerEndDate(e.target.value)}
                />
                <Checkbox
                    label="I currently work here"
                    checked={isPresentJob}
                    onChange={(e) => setIsPresentJob(e.target.checked)}
                />
                <h3 className="form-subtitle">Positions</h3>
                {
                    positions.map((position, index) => (
                        <div key={index}>
                            { index > 0 && <hr /> }
                            <p className="faux-label in-subsection">Position {index + 1}</p>
                            <div className="h-group-fields">
                                <TextInputField 
                                    label="Title"
                                    type="text"
                                    value={positions[index].title}
                                    marginRight={15}
                                    marginBottom={0}
                                    onChange={updatePosition(index, 'title')}
                                />
                                <SelectField
                                    label="Experience Level"
                                    value={positions[index].expLevel}
                                    marginBottom={15}
                                    onChange={updatePosition(index, 'expLevel')}
                                >
                                    <option value="">Select a level</option>
                                    <option value="Intern">Intern</option>   
                                    <option value="Entry Level">Entry Level</option>   
                                    <option value="Associate">Associate</option>   
                                    <option value="Mid-Senior">Mid-Senior</option>   
                                    <option value="Manager">Manager</option>   
                                    <option value="Director">Director</option>   
                                    <option value="Executive">Executive</option>   
                                </SelectField>
                            </div>
                            <div className="h-group-fields">
                                <TextInputField 
                                    label="Wage Amount"
                                    type="number"
                                    value={positions[index].wageAmt}
                                    min="0"
                                    marginRight={15}
                                    marginBottom={0}
                                    onChange={updatePosition(index, 'wageAmt')}
                                />
                                <SelectField
                                    label="Wage Type"
                                    value={positions[index].wageType}
                                    marginBottom={15}
                                    onChange={updatePosition(index, 'wageType')}
                                >
                                    <option value="hourly">Hourly</option>
                                    <option value="annually">Annually</option>   
                                </SelectField>
                            </div>
                            <div className="h-group-fields">
                                <TextInputField 
                                    label="Start Date"
                                    type="date"
                                    max={currDate}
                                    value={positions[index].startDate}
                                    pattern="\d{4}-\d{2}-\d{2}"
                                    marginRight={15}
                                    marginBottom={0}
                                    onChange={updatePosition(index, 'startDate')}
                                />
                                <TextInputField 
                                    label="End Date"
                                    type="date"
                                    max={currDate}
                                    value={positions[index].endDate || ''}
                                    pattern="\d{4}-\d{2}-\d{2}"
                                    marginRight={0}
                                    marginBottom={0}
                                    onChange={updatePosition(index, 'endDate')}
                                />
                            </div>
                        </div>
                    ))
                }
                <div 
                    className="manage-positions" 
                    role="button"
                    onClick={addNewPosition}
                >
                    <span className="add">+ Add New Position</span>
                </div>
                <br /><br />
                <div className="form-actions">
                    {
                        isEditMode &&
                        <div className="button-component minimal">
                            <Button 
                                appearance="minimal" 
                                intent="danger"
                                marginRight={15}
                                onClick={(e) => openDeleteDialog(e)}
                            >
                                Delete Employer
                            </Button>
                        </div>
                    }
                    <div className="button-component custom">
                        <Button 
                            appearance="primary" 
                            onClick={submitEmployer}
                        >
                            {isEditMode ? 'Save Employer' : 'Create Employer'}
                        </Button>
                    </div>
                </div>
            </form>
            <Dialog
                isShown={showDeleteDialog}
                onCloseComplete={() => setShowDeleteDialog(false)}
                confirmLabel="Yes"
                hasHeader={false}
                preventBodyScrolling
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEscapePress={false}
                topOffset={mode === 'desktop' ? '12vmin' : '50vmin'}
                minHeightContent={0}
                onConfirm={deleteEmployer}
            >
                <p>Are you sure you want to delete this employer? This cannot be undone.</p>
            </Dialog>
        </div>
    );
}

EmployerForm.propTypes = {
    setPageTitle: PropTypes.func.isRequired
};

export default EmployerForm;