import React, { useState, useEffect } from 'react';
import firebase from 'firebase';
import { admins } from 'utils/otherConstants';

import '../Admin.scss';

import {
    Icon,
    Table,
    Dialog,
    toaster
} from 'evergreen-ui';

const AdminUsers = ({ router }) => {
    const db = firebase.firestore();
    const [usersList, setUsersList] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [userToDelete, setUserToDelete] = useState('');
    
    useEffect(() => {
        const getUsers = async () => {
            try {
                const snapshot = await db.collection('users').get();
                let users = [];
                snapshot.forEach(async (doc) => {
                    const data = doc.data();
                    users.push(data);
                });

                setUsersList(users);
            } catch(error) {
                console.log(error);
                toaster.danger('Something went wrong retrieving your data. Please refresh the page or contact us.');
            }
        };

        getUsers();

        return () => {};
    }, [db]);

    const editUser = (id) => {
        const url = router.url({ 
            name: 'AdminUserEdit', 
            params: {
                id: id
            }
        });
        router.navigate({ url });
    };

    const openDeleteDialog = (e, userId) => {
        e.preventDefault();
        setUserToDelete(userId);
        setShowDeleteDialog(true);
    }

    const deleteUser = async () => {
        try {
            const empByUsersSnap = await db.collection('employersByUsers').where('userId', '==', userToDelete).get();
            empByUsersSnap.forEach((employer) => {
                employer.ref.delete();
            });

            const eventsSnap = await db.collection('events').where('userId', '==', userToDelete).get();
            eventsSnap.forEach((event) => {
                event.ref.delete();
            });

            const answersSnap = await db.collection('questionsAnswered').where('userId', '==', userToDelete).get();
            answersSnap.forEach((answer) => {
                answer.ref.delete();
            });

            const snapshot = await db.collection('users').where('profileId', '==', userToDelete).get();
            snapshot.docs[0].ref.delete();
            window.location.reload()
        } catch(error) {
            console.log(error);
            toaster.danger('Something went wrong with deleting this user. Please try again or contact us.');
            return;
        }
    };

    const handleClose = () => {
        setUserToDelete('');
        setShowDeleteDialog(false);
    }

    return (
        <>
            <h2 className="admin-header">Users</h2>
            {/* <div className="new-button">
                <Link className="" name="AdminUserNew">Add New</Link>
            </div> */}
            <div className="information-block">
                <Table>
                    <Table.Head>
                        <Table.TextHeaderCell>
                            Name
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell>
                            Email
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell>
                            Last Login
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell className="icon-header">
                            Edit
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell className="icon-header">
                            Delete
                        </Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body maxHeight={650}>
                        {usersList.map(user => {
                            return (
                                <Table.Row key={Math.random() * 98180182145}>
                                    <Table.TextCell>{`${user.firstName} ${user.lastName}`}</Table.TextCell>
                                    <Table.TextCell>{user.email}</Table.TextCell>
                                    <Table.TextCell>{new Date(user.lastLogin.seconds * 1000).toLocaleDateString('en-US')}</Table.TextCell>
                                    <Table.Cell className="icon-cell" isSelectable onClick={() => editUser(user.profileId)}>
                                        <Icon icon="edit" />
                                    </Table.Cell>
                                    <Table.Cell 
                                        isSelectable 
                                        className={`icon-cell ${admins.includes(user.email) ? 'disabled' : ''}`} 
                                        onClick={(e) => admins.includes(user.email) ? null : openDeleteDialog(e, user.profileId)}
                                    >
                                        <Icon icon="trash" />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
                <Dialog
                    isShown={showDeleteDialog}
                    confirmLabel="Yes"
                    hasHeader={false}
                    preventBodyScrolling
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                    minHeightContent={0}
                    onConfirm={deleteUser}
                    onCancel={() => handleClose()}
                >
                    <p>Are you sure you want to delete this user? This cannot be undone.</p>
                </Dialog>
            </div>
        </>
    );
}

export default AdminUsers;