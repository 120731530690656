import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import { Link } from '@curi/react-dom';

import './Register.scss';

import { 
    TextInputField, 
    Button,
    Checkbox,
    toaster
} from 'evergreen-ui';

const Register = ({ router, setPageTitle, setActiveRegState }) => {
    const db = firebase.firestore();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [wantsMarketing, setWantsMarketing] = useState(false);
    const [pageStep, setPageStep] = useState(1);
    const [doesPasswordMatch, setDoesPasswordMatch] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    
    setPageTitle(`Registration - Step ${pageStep} of 2`);
    setActiveRegState(pageStep);
    
    useEffect(() => {
        const isMatch = password === passwordMatch;
        setDoesPasswordMatch(isMatch);
    }, [password, passwordMatch]);

    const submitRegistration = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
            await firebase.auth().createUserWithEmailAndPassword(email.toLowerCase(), password);
            
            const cryptoRandomString = require('crypto-random-string');
            const profileId = cryptoRandomString({ length: 13 });
            const rightNow = firebase.firestore.Timestamp.fromDate(new Date());
            
            await db.collection('users').add({
                profileId: profileId,
                email: email.toLowerCase(),
                firstName: firstName,
                lastName: lastName,
                created: rightNow,
                lastLogin: rightNow
            });
            
            await signUpForNewsletter();
            const url = router.url({ name: 'Onboarding' });
            router.navigate({ url });
        } catch(error) {
            setIsLoading(false);
            showRegisterError(error);
            if (firebase.auth().currentUser) {
                firebase.auth().currentUser.delete();
            }
        }
    };

    const signUpForNewsletter = async () => {
        const data = {
            firstName: firstName,
            lastName: lastName,
            email: email.toLowerCase(),
            hasRegistered: true
        };

        try {
            return fetch(`${process.env.REACT_APP_SERVER_URL}/signUpForNewsletter`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error(error);
            return;
        }
    };

    const goToNextStep = async (e) => {
        e.preventDefault();
        
        if (isReadyForNextStep()) {
            const methods = await firebase.auth().fetchSignInMethodsForEmail(email);
            
            if (methods.length > 0) {
                toaster.danger('This email is already used. Please use another one.');
                return;
            } 
            
            setPageStep(2);
            setActiveRegState(2);
        }
    }

    const isReadyForNextStep = () => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValidEmail = re.test(String(email).toLowerCase());

        return isValidEmail && password.length >= 6 && firstName.length > 0 && lastName.length > 0 && doesPasswordMatch;
    }

    const showRegisterError = (error) => {
        if (error.code) {
            switch(error.code) {
                case 'auth/invalid-email':
                    toaster.danger('Please enter a valid email address.');
                    break;
                default:
                    console.log(error);
                    toaster.danger('Something went wrong with your registration. Please try again or contact us.');
                    break;
            };
        } else {
            console.log(error);
            toaster.danger('Something went wrong with your registration. Please try again or contact us.');
        }
    };

    const cancelRegistration = () => {
        const url = router.url({ name: 'Home' });
        router.navigate({ url });
    };

    return (
        <div className="page-wrapper register">
            {
                pageStep === 1 ? (
                    <>
                        <div className="register-logo">
                            <img alt="Good Job" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Fgj-logo-v2.png?alt=media&token=bb833b68-40fc-4db3-9f3d-f32ce96e3a72" />
                        </div>
                        <h2>Create Account</h2>
                        <p className="reg-desc">This will help us create an account for you to record your events and to make your work experience even better.</p>
                        <form onSubmit={(e) => goToNextStep(e)}>
                            <div className="h-group-fields">
                                <TextInputField
                                    label="First Name"
                                    required
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    marginRight={15}
                                />
                                <TextInputField
                                    label="Last Name"
                                    required
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </div>
                            <TextInputField
                                label="Email"
                                type="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextInputField
                                label="Password (must be 6+ characters)"
                                type="password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <TextInputField
                                label="Re-type Password"
                                type="password"
                                required
                                value={passwordMatch}
                                onChange={(e) => setPasswordMatch(e.target.value)}
                                // onBlur={checkPasswordMatch}
                                isInvalid={!doesPasswordMatch}
                            />
                            <div className="form-actions">
                                <div className="button-component custom">
                                    <Button 
                                        appearance="primary" 
                                        disabled={!isReadyForNextStep()}
                                        onClick={(e) => goToNextStep(e)}
                                    >
                                        Next
                                    </Button>
                                </div>
                                <div className="button-component minimal">
                                    <Button 
                                        appearance="minimal" 
                                        onClick={cancelRegistration}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </>
                ) : (
                    <>
                        <h2>Terms and Conditions</h2>
                        <form className="privacy-summary" onSubmit={submitRegistration}>
                            <p>
                                Good Job would like you to know that we value your privacy. By accepting the terms, 
                                you agree that you are using our service to track your career as it relates to your emotional 
                                well-being and growth. You have the option to delete your account and any of the data associated 
                                with your account at any time. To learn more, please read our 
                                full <Link name="Terms" target="_blank">terms of service</Link> and <Link name="Privacy" target="_blank">privacy policy</Link>.
                            </p>
                            <br />
                            <div className="check-component">
                                <Checkbox
                                    label="By clicking this checkbox you agree to our terms and privacy policy."
                                    checked={acceptTerms}
                                    onChange={(e) => setAcceptTerms(!acceptTerms)}
                                />
                            </div>
                            <div className="check-component">
                                <Checkbox
                                    label="I would like to receive marketing emails"
                                    checked={wantsMarketing}
                                    onChange={(e) => setWantsMarketing(!wantsMarketing)}
                                />
                            </div>
                            <div className="form-actions">
                                <div className="button-component custom">
                                    <Button 
                                        appearance="primary" 
                                        disabled={!acceptTerms}
                                        isLoading={isLoading}
                                        onClick={submitRegistration}
                                    >
                                        Register
                                    </Button>
                                </div>
                                <div className="button-component minimal">
                                    <Button 
                                        appearance="minimal" 
                                        onClick={cancelRegistration}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </>
                )
            }
        </div>
    );
}

Register.propTypes = {
    setPageTitle: PropTypes.func.isRequired
};

export default Register;