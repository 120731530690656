import React from 'react';

import './FooterPages.scss';

const About = () => {
    return (
        <div className="footer-page with-bg">
            <div className="about-blurb">
                {/* <h1>About Us</h1> */}
                <h1>👋🏼 Nice to meet you.<br />Welcome to Good Job.</h1>
                <br />
                <h3>How Good Job makes you happier at work</h3>
                <p>
                    Good Job’s workplace tracker helps you understand what makes you happy 
                    and what is causing you stress by turning your daily experiences at work into data. 
                    You can then use your data to better prepare for interactions with your peers, or use it 
                    as supporting evidence when advocating for a promotion or a raise. If it doesn’t work out 
                    we can help match you with good jobs at companies that meet your values and needs.
                </p>
                <h3>Good Job was made for you, not your company</h3>
                <p>
                    We believe you are the best advocate for your workplace wellbeing, so we put 
                    that power back in your hands. By documenting your daily experiences, you are injecting 
                    transparency into your workplace and holding your company accountable for the environment it 
                    creates for its workers. 
                </p>
                <h3>A better way to review a Company</h3>
                <p>
                    Long form subjective reviews are easily dismissed as one employee’s bad experience. 
                    Because Good Job members are tracking their every-day ups and downs, those quantified 
                    experiences become an undeniable record of the health of their employers’ work environments.
                </p>
                <h3>Our value</h3>
                <p>
                    At Good Job we believe a healthy and happy workplace is one that treats workers with respect, pays 
                    them equally for equal work, has inclusive and diverse policies baked into its DNA and just as important, 
                    considers its impact on the environment and contributions to our climate health. These are the markers we 
                    use to evaluate a workplace. We aim to prove that companies that create healthy, happy workplaces are 
                    rewarded with higher employee retention and engagement. And that customers that want to support businesses 
                    that treat their employees well and share their values. 
                    <br /><br />
                    We hope you’ll join us in our mission to hold businesses to a higher standard of employee health and 
                    happiness, and tell your coworkers! If you have any questions or comments feel free to reach out. We’d 
                    love to hear from you.
                </p>
                <p>Sincerely,</p>
                <div className="ceo-info">
                    <span className="ceo-name">Krisserin Canary</span>
                    <span className="ceo-title">Founder + CEO</span>
                    <img alt="" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Fceo-profile.jpg?alt=media&token=70791788-a6c1-47b8-8e5f-0dc8d4e68b8c" />
                </div>
            </div>
        </div>
    );
};

export default About;