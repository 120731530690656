import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import { Link } from "@curi/react-dom";
import __startCase from 'lodash/startCase';
import __isEmpty from 'lodash/isEmpty';
import __find from 'lodash/find';

import './Account.scss';

import { 
    Table,
    TextInputField, 
    Icon,
    Dialog,
    toaster
} from 'evergreen-ui';

const Account = ({ 
    router, 
    response, 
    setPageTitle, 
    currentUser, 
    setCurrentUser,
    mode 
}) => {
    const db = firebase.firestore();
    const [showChangeDialog, setShowChangeDialog] = useState(false);
    const [whatToChange, setWhatToChange] = useState(null);
    const [employers, setEmployers] = useState([]);
    const [fields, setFields] = useState({
        email: '',
        newEmail: '',
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        firstName: '',
        newFirstName: '',
        lastName: '',
        newLastName: ''
    });

    setPageTitle('My Account');

    useEffect(() => {
        const getAccountInfo = async () => {
            try {
                if (currentUser) {
                    const snapshot = await db.collection('users').where('email', '==', currentUser.email).get();
                    const data = snapshot.docs[0].data();
    
                    setFields({
                        email: currentUser.email,
                        firstName: data.firstName,
                        lastName: data.lastName
                    });

                    const tempEmployers = [];
                    const finalEmployers = [];
                    const employersSnapshot = await db.collection('employersByUsers').where('userId', '==', data.profileId).limit(3).get();
                    employersSnapshot.forEach(async (doc) => {
                        //startDate is required in the db so no ternary
                        const startDate = new Date(doc.data().startDate.seconds * 1000).toLocaleDateString('en-US', {
                            month: 'numeric',
                            day: 'numeric',
                            year: '2-digit'
                        });
                        
                        const endDate = doc.data().endDate ? new Date(doc.data().endDate.seconds * 1000).toLocaleDateString('en-US', {
                            month: 'numeric',
                            day: 'numeric',
                            year: '2-digit'
                        }) : 'Present';
                        
                        const currentPosition = __find(doc.data().positions, (position) => {
                            return position.endDate === null;
                        });
                        
                        tempEmployers.push({
                            empByUserId: doc.data().empByUserId,
                            id: doc.data().employerId,
                            currentPosition: currentPosition.title,
                            start: startDate,
                            end: endDate
                        });
                    });

                    for (const employer of tempEmployers) {
                        let employerData = employer;
                        const companySnapshot = await db.collection('companies').where('companyId', '==', employerData.id).get();
                        const data = companySnapshot.docs[0].data();
                        
                        employerData.name = data.name;
                        finalEmployers.push(employerData);
                    }

                    setEmployers(finalEmployers);
                }
            } catch(error) {
                console.log(error);
                toaster.danger('Something went wrong getting your data. Please try again or contact us.');
            }
        };

        getAccountInfo();
    }, [response.name, currentUser, db]);

    const updateField = (value, field, isDialog = false) => {
        if (isDialog) {
            if (field.toLowerCase().includes('password')) {
                setFields((prevState) => ({ 
                    ...prevState, 
                    [field]: value
                }));
            } else {
                setFields((prevState) => ({ 
                    ...prevState, 
                    [`new${toStartCamelCase(field)}`]: value
                }));
            }
        } else {
            setFields((prevState) => ({ 
                ...prevState, 
                [field]: value
            }));
        }
    };

    const toStartCamelCase = (text) => {
        const startCase = __startCase(text);
        return startCase.replace(' ', '');
    };

    const openChangeDialog = (field) => {
        setWhatToChange(field);
        setShowChangeDialog(true);
    }

    const saveInfo = async (close) => {
        try {
            const snapshot = await db.collection('users').where('email', '==', currentUser.email).get();
            const userRef = snapshot.docs[0].ref;
            
            if (whatToChange === 'password') {
                if (fields.newPassword !== fields.newPasswordConfirm) {
                    throw(new Error('Passwords do not match'));
                }
                
                const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, fields.oldPassword);
                await firebase.auth().currentUser.reauthenticateWithCredential(credential);
                await firebase.auth().currentUser.updatePassword(fields.newPassword);
            } else if (whatToChange === 'firstName') {
                await userRef.update({ firstName: fields.newFirstName });
                updateField(fields.newFirstName, 'firstName');
            } else if (whatToChange === 'lastName') {
                await userRef.update({ lastName: fields.newLastName });
                updateField(fields.newLastName, 'lastName');
            } else if (whatToChange === 'email') {
                firebase.auth().currentUser.updateEmail(fields.newEmail);
                await userRef.update({ email: fields.newEmail });
                updateField(fields.newEmail, 'email');
            } 

            //if either name part changed, edit the Firebase profile too
            if (whatToChange === 'firstName' || whatToChange === 'lastName') {
                await firebase.auth().currentUser.updateProfile({ displayName: `${fields.newFirstName} ${fields.newLastName}` });
            }

            toaster.success('Your information has been updated.');
            close();
        } catch(error) {
            console.log(error);
            if (error.code) {
                if (error.code === 'auth/wrong-password') {
                    toaster.danger('Your current password is incorrect. Please try again.');
                }
            } else {
                toaster.danger('Something went wrong saving your data. Please try again or contact us.');
            }

            return;
        }
    }

    const goToDelete = () => {
        const url = router.url({ name: 'DeleteAccount' });
        router.navigate({ url });
    };

    const editEmployer = (id) => {
        const url = router.url({ 
            name: 'EditEmployer', 
            params: {
                id: id
            }
        });
        router.navigate({ url });
    };

    const finishDialogActions = () => {
        updateField('', 'firstName', true);
        updateField('', 'lastName', true);
        updateField('', 'email', true);
        updateField('', 'oldPassword', true);
        updateField('', 'newPassword', true);
        updateField('', 'newPasswordConfirm', true);
        setShowChangeDialog(false)
    };

    const logout = async (e) => {
        e.preventDefault();
        try {
            await firebase.auth().signOut();
            setCurrentUser(null);

            const url = router.url({ name: 'Home' });
            router.navigate({ url });
        } catch(error) {
            console.log(error);
            toaster.danger('Something went wrong with logging out. Please try again or contact us.');
        }
    }

    return (
        <div className="page-wrapper maxed account">
            <div className="log-out-section">
                <button onClick={logout}>Log Out</button>
            </div>
            <div className="section">
                <h3>Basic Information</h3>
                <div className="information-block">
                    <div role="button" className="view-account-info" onClick={() => openChangeDialog('firstName')}>
                        <div className="account-info-title">First Name</div>
                        <div className="account-info-value">
                            <span>{fields.firstName}</span>
                            <Icon icon="chevron-right" marginLeft={5} />
                        </div>
                    </div>
                    <div role="button" className="view-account-info" onClick={() => openChangeDialog('lastName')}>
                        <div className="account-info-title">Last Name</div>
                        <div className="account-info-value">
                            <span>{fields.lastName}</span>
                            <Icon icon="chevron-right" marginLeft={5} />
                        </div>
                    </div>
                    <div role="button" className="view-account-info" onClick={() => openChangeDialog('email')}>
                        <div className="account-info-title">Email</div>
                        <div className="account-info-value">
                            <span>{fields.email}</span>
                            <Icon icon="chevron-right" marginLeft={5} />
                        </div>
                    </div>
                    <div role="button" className="view-account-info" onClick={() => openChangeDialog('password')}>
                        <div className="account-info-title">Change Password</div>
                        <div className="account-info-value">
                            <Icon icon="chevron-right" marginLeft={5} />
                        </div>
                    </div>
                </div>
            </div>
            <br /><br />
            <div className="section">
                <h3>Workplace History</h3>
                <div className="information-block">
                    <Table>
                        <Table.Head className="table-head">
                            <Table.TextHeaderCell>
                                Company
                            </Table.TextHeaderCell>
                            <Table.TextHeaderCell>
                                Position
                            </Table.TextHeaderCell>
                            <Table.TextHeaderCell>
                                Start
                            </Table.TextHeaderCell>
                            <Table.TextHeaderCell>
                                End
                            </Table.TextHeaderCell>
                        </Table.Head>
                        <Table.Body>
                            {employers.map(employer => (
                                <Table.Row 
                                    key={employer.empByUserId}
                                    isSelectable
                                    onSelect={() => editEmployer(employer.empByUserId)}
                                >
                                    <Table.TextCell className="custom-cell">{employer.name}</Table.TextCell>
                                    <Table.TextCell className="custom-cell">{employer.currentPosition}</Table.TextCell>
                                    <Table.TextCell className="custom-cell">{employer.start}</Table.TextCell>
                                    <Table.TextCell className="custom-cell">{employer.end}</Table.TextCell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
                <div className="button-component custom">
                    <Link className="get-started-button" name="Employers">See All Employers</Link>
                </div>
            </div>
            <br /><br />
            <div className="section">
                <div className="information-block single-link">
                    <div role="button" className="view-account-info" onClick={goToDelete}>
                        <div className="account-info-title danger">Delete Account</div>
                        <div className="account-info-value">
                            <Icon icon="chevron-right" color="danger" marginLeft={5} />
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                isShown={showChangeDialog}
                onCloseComplete={finishDialogActions}
                confirmLabel="Change"
                hasHeader={false}
                preventBodyScrolling
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEscapePress={false}
                topOffset={mode === 'desktop' ? '12vmin' : '50vmin'}
                minHeightContent={0}
                onConfirm={saveInfo}
                isConfirmDisabled={
                    whatToChange === 'password' ? 
                    __isEmpty(fields.oldPassword) || 
                    __isEmpty(fields.newPassword) || fields.newPassword.length < 6 || 
                    __isEmpty(fields.newPasswordConfirm) || fields.newPasswordConfirm.length < 6 :
                    __isEmpty(fields[`new${toStartCamelCase(whatToChange)}`])}
            >
                {
                    whatToChange === 'password' ? (
                        <>
                            <TextInputField
                                label="Current Password"
                                name="changePassword"
                                required
                                type="password"
                                value={fields.oldPassword}
                                onChange={(e) => updateField(e.target.value, 'oldPassword', true)}
                            />
                            <TextInputField
                                label="New Password (must be 6+ characters)"
                                name="newPassword"
                                required
                                type="password"
                                value={fields.newPassword}
                                onChange={(e) => updateField(e.target.value, 'newPassword', true)}
                            />
                            <TextInputField
                                label="Confirm Password"
                                name="newPasswordConfirm"
                                required
                                type="password"
                                value={fields.newPasswordConfirm}
                                onChange={(e) => updateField(e.target.value, 'newPasswordConfirm', true)}
                            />
                        </>
                    ) : (
                        <TextInputField
                            label={toStartCamelCase(whatToChange)}
                            name={whatToChange}
                            required
                            type={whatToChange === 'email' ? 'email' : 'text'}
                            value={fields[`new${toStartCamelCase(whatToChange)}`]}
                            onChange={(e) => updateField(e.target.value, whatToChange, true)}
                        />
                    )
                }
            </Dialog>
        </div>
    );
}

Account.propTypes = {
    setPageTitle: PropTypes.func.isRequired
};

export default Account;