import React, { useState, useEffect } from 'react';
import { Link } from '@curi/react-dom';
import firebase from 'firebase';
import __isEmpty from 'lodash/isEmpty';

import '../Admin.scss';

import {
    Icon,
    Table,
    Dialog,
    toaster
} from 'evergreen-ui';

const { ms, d, mo } = require('utils/timeConverter');
const AdminQuestions = ({ router }) => {
    const db = firebase.firestore();
    const [questionsList, setQuestionsList] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [questionToDelete, setQuestionToDelete] = useState('');
    
    useEffect(() => {
        const getQuestions = async () => {
            try {
                const snapshot = await db.collection('questions').get();
                let questions = [];
                snapshot.forEach(async (doc) => {
                    const data = doc.data();
                    questions.push(data);
                });

                setQuestionsList(questions);
            } catch(error) {
                console.log(error);
                toaster.danger('Something went wrong retrieving your data. Please refresh the page or contact us.');
            }
        };

        getQuestions();

        return () => {};
    }, [db]);

    const editQuestion = (id) => {
        const url = router.url({ 
            name: 'AdminQuestionEdit', 
            params: {
                id: id
            }
        });
        router.navigate({ url });
    };

    const openDeleteDialog = (e, questionId) => {
        e.preventDefault();
        setQuestionToDelete(questionId);
        setShowDeleteDialog(true);
    }

    const deleteQuestion = async () => {
        try {
            const snapshot = await db.collection('questions').where('questionId', '==', questionToDelete).get();
            snapshot.docs[0].ref.delete();
            window.location.reload()
        } catch(error) {
            console.log(error);
            toaster.danger('Something went wrong with deleting this question. Please try again or contact us.');
            return;
        }
    };

    const handleClose = () => {
        setQuestionToDelete('');
        setShowDeleteDialog(false);
    }

    return (
        <>
            <h2 className="admin-header">Questions</h2>
            <div className="new-button">
                <Link className="" name="AdminQuestionNew">Add New</Link>
            </div>
            <div className="information-block">
                <Table>
                    <Table.Head className="questions-header-row">
                        <Table.TextHeaderCell className="number-header">
                            #
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell className="wide-header">
                            Question Text
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell>
                            Type
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell>
                            Priority
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell>
                            Has Follow-up?
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell>
                            Frequency
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell className="icon-header">
                            Edit
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell className="icon-header">
                            Delete
                        </Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body maxHeight={650}>
                        {questionsList.map(question => {
                            const converted = ms.to(mo, d)(question.frequency * 1000);
                            const frequency = converted[0] !== 0 ? converted[0] : converted[1];
                            const unit = frequency === converted[0] ? frequency === 1 ? 'month' : 'months' : 'days';
                            const fullFrequency = frequency === 0 ? 'None' : `${frequency} ${unit}`;

                            return (
                                <Table.Row 
                                    key={Math.random() * 98180182145} 
                                    className={`
                                        table-row row-id-${question.questionId} 
                                        ${__isEmpty(question.parentQId) ? '' : `has-parent`} 
                                    `}
                                >
                                    <Table.Cell className="number-cell"><span>{question.order}</span></Table.Cell>
                                    <Table.Cell className="wide-cell"><span>{question.text}</span></Table.Cell>
                                    <Table.TextCell>{question.type}</Table.TextCell>
                                    <Table.TextCell>{question.priority}</Table.TextCell>
                                    <Table.TextCell>{__isEmpty(question.childQId) ? 'No' : 'Yes'}</Table.TextCell>
                                    <Table.TextCell>{fullFrequency}</Table.TextCell>
                                    <Table.Cell className="icon-cell" isSelectable onClick={() => editQuestion(question.questionId)}>
                                        <Icon icon="edit" />
                                    </Table.Cell>
                                    <Table.Cell className="icon-cell" isSelectable onClick={(e) => openDeleteDialog(e, question.questionId)}>
                                        <Icon icon="trash" />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
                <Dialog
                    isShown={showDeleteDialog}
                    confirmLabel="Yes"
                    hasHeader={false}
                    preventBodyScrolling
                    shouldCloseOnOverlayClick={false}
                    shouldCloseOnEscapePress={false}
                    minHeightContent={0}
                    onConfirm={deleteQuestion}
                    onCancel={() => handleClose()}
                >
                    <p>Are you sure you want to delete this question? This cannot be undone.</p>
                </Dialog>
            </div>
        </>
    );
}

export default AdminQuestions;