import React from 'react';

import Default from './Default';
import Authenticated from './Authenticated';

const Home = ({ currentUser }) => {
    if (currentUser) {
        return <Authenticated currentUser={currentUser} />;
    }
    
    return <Default currentUser={currentUser} />;
}

export default Home;