import React, { useState } from 'react';
import { Link } from '@curi/react-dom';

import './Home.scss';

import { 
    TextInput,
    Button,
    toaster
} from 'evergreen-ui';

const DefaultHome = () => {
    const [newsFirstName, setNewsFirstName] = useState('');
    const [newsLastName, setNewsLastName] = useState('');
    const [newsEmail, setNewsEmail] = useState('');
    const [isSubbing, setIsSubbing] = useState(false);
    const [showNewsForm, setShowNewsForm] = useState(true);

    const signUpForNewsletter = (e) => {
        e.preventDefault();
        setIsSubbing(true);

        const data = {
            firstName: newsFirstName,
            lastName: newsLastName,
            email: newsEmail
        };

        fetch(`${process.env.REACT_APP_SERVER_URL}/signUpForNewsletter`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(() => {
            setShowNewsForm(false);
        })
        .catch((error) => {
            console.error(error);
            toaster.danger('We could not subscribe you to the newsletter. Please try again or contact us.');
        }).finally(() => setIsSubbing(false));
    };
    
    return (
        <div className="home">
            <div className="section-padding"></div>
            <div className="section hero">
                <div className="inner-content">
                    <h2>Welcome to Good Job!</h2>
                    <p className="subline">
                        Take note of your day
                        <br />
                        Be happier at work.
                    </p>
                    <div className="button-component custom">
                        <Link className="get-started-button" name="Register">Get Started</Link>
                    </div>
                </div>
            </div>
            <div className="section-padding">
                <div className="title-wrapper">
                    <h2 className="works-title">How It Works</h2>
                </div>
            </div>
            <div className="section marketing">
                <div>
                    <div className="marketing-point">
                        <div className="marketing-icon left"><img alt="" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Fcheck.svg?alt=media&token=3891e3fb-cdc3-4be1-b30a-14b9aebf6580" /></div>
                        <div>
                            <h3>1. Track micro-moments</h3>
                            <p>Note an event, move on with your day.</p>
                        </div>
                    </div>
                    <div className="marketing-point">
                        <div>
                            <h3>2. Identify trends</h3>
                            <p>Document interactions to support your “gut” feelings.</p>
                        </div>
                        <div className="marketing-icon right"><img alt="" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Freport.svg?alt=media&token=a95ee5bb-3e02-4611-8e00-2de27265ae2b" /></div>
                    </div>
                    <div className="marketing-point">
                        <div className="marketing-icon left"><img alt="" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Fcalendar.svg?alt=media&token=0732d74e-51f8-47f4-b5dc-0fa8a8978523" /></div>
                        <div>
                            <h3>3. Record milestones</h3>
                            <p>Track important meetings, performance reviews, and even regular 1:1 meetings with your boss.</p>
                        </div>
                    </div>
                    <div className="marketing-point">
                        <div>
                            <h3>4. Be happier at work</h3>
                            <p>Have the data you need to make decisions about the future of your career.</p>
                        </div>
                        <div className="marketing-icon right"><img alt="" src="https://firebasestorage.googleapis.com/v0/b/workplace-tracker-live.appspot.com/o/assets%2Fhappy.svg?alt=media&token=edc09656-8a65-4768-bcdb-5793d075618e" /></div>
                    </div>
                </div>
            </div>
            <br />
            <div className="section newsletter">
                {
                    showNewsForm ? (
                        <>
                            <h2 className="newsletter-header">Good Job Newsletter</h2>
                            <p>Get career advice, workplace data and more delivered to your inbox.</p>
                            <form className="mailchimp-list-form" onSubmit={signUpForNewsletter}>
                                <TextInput type="text" placeholder="First Name*" required marginBottom={15} onChange={(e) => setNewsFirstName(e.target.value)} />
                                <TextInput type="text" placeholder="Last Name*" required marginBottom={15} onChange={(e) => setNewsLastName(e.target.value)} />
                                <TextInput type="email" placeholder="Email*" required marginBottom={15} onChange={(e) => setNewsEmail(e.target.value)} />
                                <div className="button-component custom">
                                    <Button appearance="primary" disabled={isSubbing} onClick={signUpForNewsletter}>Subscribe</Button>
                                </div>
                            </form>
                        </>
                    ) : (
                        <h3 style={{textAlign: 'center'}}>Thank you for signing up for our newsletter!</h3>
                    )
                }
            </div>
        </div>
    );
}

export default DefaultHome;