import React, { useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';

import './LinkAccountLogin.scss';

import {
    TextInputField,
    Button,
    toaster
} from 'evergreen-ui';


const LinkAccountLogin= ({ router, setPageTitle }) => {
    const db = firebase.firestore();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    setPageTitle('Link Account');

    const linkAccount = async (email, team_id, token, channel) => {

        const data = {
            slackUserToken: token,
            goodJobEmail: email,
            channel: channel,
            teamId: team_id
        };
        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/slackApi/api/user`;
            console.log(url);
            return fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        } catch (error) {
            console.error(error);
            return;
        }
    };

    const submitLogin = async (e) => {
        e.preventDefault();
        const urlParts = document.URL.split('/');
        const channel = urlParts.pop();
        const token = urlParts.pop();
        const teamId = urlParts.pop();
        try {
            await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
            await firebase.auth().signInWithEmailAndPassword(email, password);
            const currentUser = firebase.auth().currentUser;
            const linkResult = await linkAccount(currentUser.email, teamId, token, channel);
            const linkJson = await linkResult.json();
            const rightNow = firebase.firestore.Timestamp.fromDate(new Date());
            const snapshot = await db.collection('users').where('email', '==', email).get();


            //should only be one result
            await snapshot.docs[0].ref.update({
                lastLogin: rightNow
            });

            const url = router.url({ name: 'LinkAccountSuccess' });
            router.navigate({ url });
        } catch(error) {

            showLoginError(error);
            return;
        }
    };

    const showLoginError = (error) => {
        if (error.code) {
            switch(error.code) {
                case 'auth/user-not-found':
                case 'auth/wrong-password':
                    toaster.danger('The email/password combination you entered is invalid.');
                    break;
                case 'auth/invalid-email':
                    toaster.danger('Please enter a valid email address.');
                    break;
                default:
                    console.log(error);
                    toaster.danger('Something went wrong with linking your account. Please try again or contact us.');
                    break;
            };
        } else {
            console.log(error);
            toaster.danger('Something went wrong with linking your account. Please try again or contact us.');
        }
    };

    return (
        <div className="page-wrapper maxed login">
            <p className="login-desc">Welcome! Please log in below to link your account to Slack.</p>
            <form onSubmit={submitLogin}>
                <TextInputField
                    label="Email"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextInputField
                    label="Password"
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className="button-component custom">
                    <Button
                        appearance="primary"
                        onClick={submitLogin}
                    >
                        Log In
                    </Button>
                </div>
            </form>
        </div>
    );
}

LinkAccountLogin.propTypes = {
    setPageTitle: PropTypes.func.isRequired
};

export default LinkAccountLogin;