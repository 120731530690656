import React, { useState, useEffect } from 'react';
import firebase from 'firebase';

import '../Admin.scss';

import {
    Table,
    toaster
} from 'evergreen-ui';

const AdminCompanies = () => {
    const db = firebase.firestore();
    const [companiesList, setCompaniesList] = useState([]);
    
    useEffect(() => {
        const getCompanies = async () => {
            try {
                const snapshot = await db.collection('companies').get();
                let companies = [];
                snapshot.forEach(async (doc) => {
                    const data = doc.data();
                    companies.push(data);
                });

                setCompaniesList(companies);
            } catch(error) {
                console.log(error);
                toaster.danger('Something went wrong retrieving the list of companies. Please refresh the page or contact us.');
            }
        };

        getCompanies();

        return () => {};
    }, [db]);

    return (
        <>
            <h2 className="admin-header">Companies Represented</h2>
            {/* <div className="new-button">
                <Link className="" name="AdminUserNew">Add New</Link>
            </div> */}
            <div className="information-block">
                <Table>
                    <Table.Head>
                        <Table.TextHeaderCell>
                            Name
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell>
                            Website
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell>
                            Logo
                        </Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body maxHeight={650}>
                        {companiesList.map(company => {
                            return (
                                <Table.Row key={Math.random() * 98180182145}>
                                    <Table.TextCell>{company.name}</Table.TextCell>
                                    <Table.TextCell>
                                        <a href={`http://${company.domain}`} target="_blank" rel="noopener noreferrer">
                                            {company.domain}
                                        </a>
                                    </Table.TextCell>
                                    <Table.Cell>
                                        <img class="company-logo" alt="" src={company.logo} />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
            </div>
        </>
    );
}

export default AdminCompanies;