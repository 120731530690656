/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link } from '@curi/react-dom';
import firebase from 'firebase';
import __remove from 'lodash/remove';
import __isEmpty from 'lodash/isEmpty';

import './Home.scss';

import {
    Button,
    toaster
} from 'evergreen-ui';

const AuthenticatedHome = ({ currentUser }) => {
    const db = firebase.firestore();
    const [userId, setUserId] = useState('');
    const [questionsToAsk, setQuestionsToAsk] = useState([]);
    const [currQIndex, setCurrQIndex] = useState(0);
    const [isFinishedAnswering, setIsFinishedAnswering] = useState(false);
    const [showQEnding, setShowQEnding] = useState(false);
    const [isLoadingQs, setIsLoadingQs] = useState(true);
    
    useEffect(() => {
        const getHandledQuestions = async () => {
            const userSnap = await db.collection('users').where('email', '==', currentUser.email).get();
            const userData = userSnap.docs[0].data();
            const skipped = userData.skippedQs;
            setUserId(userData.profileId);
            
            let tempAnswered = [];
            let answered = [];
            const answeredSnap = await db.collection('questionsAnswered').where('userId', '==', userData.profileId).get();

            answeredSnap.forEach((question) => {
                tempAnswered.push(question.data());
            });

            for (const question of tempAnswered) {
                const singleQSnap = await db.collection('questions').where('questionId', '==', question.questionId).get();
                const questionData = singleQSnap.docs[0].data();
                const questionFull = {...questionData, ...question};

                answered.push(questionFull);
            }
            
            setTimeout(() => {
                buildQuestionListToIgnore(skipped, answered);
            }, 300);
        };

        const buildQuestionListToIgnore = (skipped, answered) => {
            let ignored = [];

            if (skipped) {
                ignored = [...skipped];
            }

            for (const question of answered) {
                //ignore all one-time questions
                if (!question.frequency) {
                    ignored.push(question.questionId);
                }

                const rightNow = new Date().getSeconds();
                const dateAnswered = question.dateAnswered.seconds;
                const timeElapsed = rightNow - dateAnswered;
                
                //if repeatable but time has not expired yet
                if (question.frequency && timeElapsed < question.frequency) {
                    ignored.push(question.questionId);
                }
            }

            buildQuestionListToAsk(ignored);
        };

        const buildQuestionListToAsk = async (ignored) => {
            let questionsToAsk = [];
            const questionsSnap = await db.collection('questions').get();
            questionsSnap.forEach((question) => {
                questionsToAsk.push(question.data());
            });

            //returns an array of removed elements but we don't need it
            __remove(questionsToAsk, (question) => ignored.includes(question.questionId));
            setQuestionsToAsk(questionsToAsk);
            setIsLoadingQs(false);
        };

        if (currentUser && __isEmpty(questionsToAsk)) {
            getHandledQuestions();
        }

        return () => {};
    }, []);

    const submitAnswer = async (e, answerGiven) => {
        e.preventDefault();

        try {
            const rightNow = firebase.firestore.Timestamp.fromDate(new Date());
            const cryptoRandomString = require('crypto-random-string');
            const questionAnsweredId = cryptoRandomString({ length: 13 });

            await db.collection('questionsAnswered').add({
                questionAnsweredId: questionAnsweredId,
                answer: answerGiven,
                userId: userId,
                dateAnswered: rightNow,
                questionId: questionsToAsk[currQIndex].questionId,
            });

            let currIndex = currQIndex;
            setCurrQIndex(++currIndex);

            if (currIndex === 3) {
                setIsFinishedAnswering(true);
            }
        } catch(error) {
            console.log(error);
            toaster.danger('Something went wrong with answering your question. Please try again or contact us.');
            return;
        }
    };

    const skipQuestion = async (isPermanent) => {
        if (isPermanent) {
            const snapshot = await db.collection('users').where('profileId', '==', userId).get();
            let skippedQs = snapshot.docs[0].data().skippedQs;

            if (skippedQs) {
                skippedQs.push(questionsToAsk[currQIndex].questionId);
            } else {
                skippedQs = [questionsToAsk[currQIndex].questionId];
            }

            await snapshot.docs[0].ref.update({
                skippedQs: skippedQs
            });
        }

        let currIndex = currQIndex;
        setCurrQIndex(++currIndex); 
    };
    
    if (isLoadingQs) {
        return (
            <div className="page-wrapper home">
                <div className="section">
                    <h2>How are you feeling today?</h2>
                    <div className="button-component custom">
                        <Link className="get-started-button" name="NewEvent">Add New Event</Link>
                    </div>
                </div>
                <div className="section loading-indicator">
                    <div className={`lds-ring ${isLoadingQs ? '' : 'hide'}`}><div></div><div></div><div></div><div></div></div>
                    Loading your questions...
                </div>
            </div>
        );
    }

    return (
        <div className="page-wrapper home">
            <div className="section">
                <h2>How are you feeling today?</h2>
                <div className="button-component custom">
                    <Link className="get-started-button" name="NewEvent">Add New Event</Link>
                </div>
                {
                    (
                        !__isEmpty(questionsToAsk) && 
                        !__isEmpty(questionsToAsk[currQIndex]) && 
                        !isFinishedAnswering
                    ) &&
                    <div className="question-section">
                        <div className="question-section-title">
                            <h3>Employer Scorecard</h3>
                        </div>
                        <div className="question-block">
                            <p className="question-text">{questionsToAsk[currQIndex].text}</p>
                            <div className="answer-options">
                                {
                                    questionsToAsk[currQIndex].choices.map((choice) => (
                                        <div key={choice} className="button-component custom">
                                            <Button appearance="primary" onClick={(e) => submitAnswer(e, choice)}>
                                                <span>{choice}</span>
                                            </Button>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="skip-block">
                                <div role="button" onClick={() => skipQuestion()}>Skip for now</div>
                                <div role="button" onClick={() => skipQuestion(true)}>Don't ask again</div>
                            </div>
                        </div>
                    </div>
                }
                {
                    (isFinishedAnswering && !showQEnding) &&
                    <div className="question-section">
                        <div className="question-section-title">
                            <h3>Employer Scorecard</h3>
                        </div>
                        <div className="question-block">
                            <p className="question-text">That's all for now!</p>
                            <div className="answer-options">
                                <div className="button-component custom">
                                    <Button appearance="primary" onClick={() => setIsFinishedAnswering(false)}>Keep going!</Button>
                                </div>
                                <div className="text-button-answer" role="button" onClick={() => setShowQEnding(true)}>Done for now</div>
                            </div>
                        </div>
                    </div>
                }
                {
                    (__isEmpty(questionsToAsk[currQIndex]) && !isFinishedAnswering && !isLoadingQs) &&
                    <div className="question-section">
                        <div className="question-section-title">
                            <h3>Employer Scorecard</h3>
                        </div>
                        <div className="question-block">
                            <p className="question-text">
                                Thank you!
                                <br /><br />
                                We have no more questions to ask you at the moment. Please re-visit as we continue to add new ones.
                            </p>
                        </div>
                    </div>
                }
                {
                    (showQEnding && !isLoadingQs) &&
                    <div className="question-section">
                        <div className="question-section-title">
                            <h3>Employer Scorecard</h3>
                        </div>
                        <div className="question-block">
                            <p className="question-text">
                                Thank you!
                                <br /><br />
                                Answering questions in the scorecard is an important factor in measuring your workplace well-being over time.
                            </p>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default AuthenticatedHome;