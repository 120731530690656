/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import firebase from 'firebase';

import '../Admin.scss';

import {
    TextInputField,
    Button,
    toaster
} from 'evergreen-ui';

const AdminDashboard = ({ router, currentUser }) => {
    const db = firebase.firestore();
    const [totalUsers, setTotalUsers] = useState('');
    const [totalAnswers, setTotalAnswers] = useState('');
    const [totalCompanies, setTotalCompanies] = useState('');
    const [totalEvents, setTotalEvents] = useState('');
    
    useEffect(() => {
        const getUserCount = async () => {
            try {
                const userSnapshot = await db.collection('users').get();
                setTotalUsers(userSnapshot.size);
            } catch(error) {
                console.log(error);
                toaster.danger('Something went wrong getting users.');
            }
        };

        const getAnswersCount = async () => {
            try {
                const answersSnapshot = await db.collection('questionsAnswered').get();
                console.log(answersSnapshot.size);
                setTotalAnswers(answersSnapshot.size);
            } catch(error) {
                console.log(error);
                toaster.danger('Something went wrong getting answers.');
            }
        };

        const getEventsCount = async () => {
            try {
                const eventsSnapshot = await db.collection('events').get();
                setTotalEvents(eventsSnapshot.size);
            } catch(error) {
                console.log(error);
                toaster.danger('Something went wrong getting events.');
            }
        };

        const getCompaniesCount = async () => {
            try {
                const companiesSnapshot = await db.collection('companies').get();
                setTotalCompanies(companiesSnapshot.size);
            } catch(error) {
                console.log(error);
                toaster.danger('Something went wrong getting companies.');
            }
        };

        getUserCount();
        getAnswersCount();
        getEventsCount();
        getCompaniesCount();

        return () => {};
    }, []);

    return (
        <>
            <h2 className="admin-header">Dashboard</h2>
            <div className="dashboard-counts">
                <div className="count-block">
                    <h3>{ totalUsers }</h3>
                    <p>Users</p>
                </div>
                <div className="count-block">
                    <h3>{ totalEvents }</h3>
                    <p>Events</p>
                </div>
                <div className="count-block">
                    <h3>{ totalAnswers }</h3>
                    <p>Answers</p>
                </div>
                <div className="count-block">
                    <h3>{ totalCompanies }</h3>
                    <p>Companies</p>
                </div>
            </div>
        </>
    );
}

export default AdminDashboard;