import React, { useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import allCities from 'utils/cities';
import states from 'utils/states';
import moment from 'moment';
import __isEmpty from 'lodash/isEmpty';

import './Onboarding.scss';

import Autocomplete from 'react-autocomplete';
import { 
    Select, 
    RadioGroup,
    TextInput,
    Button,
    toaster
} from 'evergreen-ui';

const Onboarding = ({ router, setPageTitle, setActiveRegState }) => {
    const db = firebase.firestore();
    const [gender, setGender] = useState('Female');
    const [race, setRace] = useState([]);
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [selectedEmployerName, setSelectedEmployerName] = useState('');
    const [employerNamesList, setEmployerNamesList] = useState([]);
    const [employerDBList, setEmployerDBList] = useState([]);
    const [position, setPosition] = useState('');
    const [expLevel, setExpLevel] = useState('Intern');
    const [citiesList, setCitiesList] = useState('');
    const [jobFunction, setJobFunction] = useState('Support');
    
    const currDate = moment().format('YYYY-MM-DD');
    const [startDate, setStartDate] = useState(currDate);
    const [isLoading, setIsLoading] = useState(false);

    setPageTitle('Onboarding');
    setActiveRegState(3);

    const submitOnboarding = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const email = firebase.auth().currentUser.email;
            const userSnapshot = await db.collection('users').where('email', '==', email).get();
            
            //should only be one result
            await userSnapshot.docs[0].ref.update({
                gender: gender,
                race: race,
                jobFunction: jobFunction,
                location: {
                    city: city,
                    state: state,
                    country: 'United States'
                }
            });

            //now save the company data
            const companyToSave = employerDBList.find((company) => {
                return company.name === selectedEmployerName;
            });

            const cryptoRandomString = require('crypto-random-string');
            let companyId = '';
            let companySnapshot = null;
            
            if (companyToSave) {
                companySnapshot = await db.collection('companies').where('domain', '==', companyToSave.domain).get();
            }
            
            if (!companySnapshot) {
                companyId = cryptoRandomString({ length: 13 });

                await db.collection('companies').add({
                    companyId: companyId,
                    name: selectedEmployerName,
                    domain: '',
                    logo: ''
                });
            } else if (companySnapshot.empty) {
                companyId = cryptoRandomString({ length: 13 });

                await db.collection('companies').add({
                    companyId: companyId,
                    name: companyToSave.name,
                    domain: companyToSave.domain,
                    logo: companyToSave.logo
                });
            } else {
                //should be only one since domains are unique
                companyId = companySnapshot.docs[0].data().companyId;
            }
            
            //lastly, save the user's company info
            const userId = userSnapshot.docs[0].data().profileId;
            const empByUserId = cryptoRandomString({ length: 13 });

            db.collection('employersByUsers').add({
                empByUserId: empByUserId,
                employerId: companyId,
                userId: userId,
                startDate: firebase.firestore.Timestamp.fromDate(new Date(startDate)),
                endDate: null,
                positions: [
                    {
                        title: position, 
                        expLevel: expLevel, 
                        wageAmt: 0,
                        wageType: 'hr',
                        startDate: firebase.firestore.Timestamp.fromDate(new Date(startDate)),
                        endDate: null
                    }
                ]
            });
            
            const url = router.url({ name: 'Home' });
            router.navigate({ url });
        } catch(error) {
            setIsLoading(false);
            console.log(error);
            toaster.danger('Something went wrong saving your data. Please try again or contact us.');
            return;
        }
    };

    const getEmployers = async (e) => {
        setSelectedEmployerName(e.target.value)
        const employer = e.target.value;
        if (employer.length > 1) {
            const response = await fetch(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${employer}`)
            const json = await response.json();

            let employerNames = [];
            for (let i = 0; i < json.length; i++) {
                const name = json[i].name;
                employerNames.push(name);
            }
            //for the options in the autocomplete
            setEmployerNamesList(employerNames);

            //to use in the db
            setEmployerDBList(json);
        }
    };

    const selectEmployer = (employerName) => {
        setSelectedEmployerName(employerName);
        setEmployerNamesList([]);
    };

    const getCities = (value) => {
        setCity(value);
        if (value.length > 1) {
            const list = allCities.filter(possibleCity => possibleCity.toLowerCase().includes(city.toLowerCase()));
            setCitiesList(list);
        }
    };

    const selectCity = (cityName) => {
        setCity(cityName);
        setCitiesList([]);
    };

    const saveRace = (value) => {
        let currRace = race;

        if (value === 'Decline to State') {
            currRace = ['Decline to State'];
        } else {
            if (currRace.includes('Decline to State')) {
                currRace = [];
            }
            
            if (currRace.includes(value)) {
                const index = currRace.indexOf(value);
                currRace.splice(index);
            } else {
                currRace.push(value);
            }
        }

        setRace([...currRace]);
    };
    
    return (
        <div className="page-wrapper onboarding">
            <p className="onboarding-desc">Just a few more things to get you started...</p>
            <form onSubmit={submitOnboarding}>
                <div className="radio-group">
                    <p className="faux-label">Gender *</p>
                    <RadioGroup 
                        value={gender}
                        size={16}
                        onChange={(value) => setGender(value)}
                        options={[
                            { label: 'Female', value: 'Female' },
                            { label: 'Male', value: 'Male' },
                            { label: 'Non-Binary', value: 'Non-Binary' }
                        ]}
                    />
                </div>
                <div>
                    <p className="faux-label no-margin">Which race/ethnicity best describes you? *</p>
                    <span className="sub-faux-label">Select all that apply</span>
                    <div className="select-options">
                        <div className={`option ${race.includes('Arab/Middle Eastern') ? 'selected' : ''}`} onClick={() => saveRace('Arab/Middle Eastern')}>Arab/Middle Eastern</div>
                        <div className={`option ${race.includes('Black/Afro-Caribbean/African') ? 'selected' : ''}`} onClick={() => saveRace('Black/Afro-Caribbean/African')}>Black/Afro-Caribbean/African</div>
                        <div className={`option ${race.includes('East Asian') ? 'selected' : ''}`} onClick={() => saveRace('East Asian')}>East Asian</div>
                        <div className={`option ${race.includes('Latinx/Hispanic') ? 'selected' : ''}`} onClick={() => saveRace('Latinx/Hispanic')}>Latinx/Hispanic</div>
                        <div className={`option ${race.includes('Pacific Islander') ? 'selected' : ''}`} onClick={() => saveRace('Pacific Islander')}>Pacific Islander</div>
                        <div className={`option ${race.includes('Native/Alaskan Native') ? 'selected' : ''}`} onClick={() => saveRace('Native/Alaskan Native')}>Native/Alaskan Native</div>
                        <div className={`option ${race.includes('Southeast Asian') ? 'selected' : ''}`} onClick={() => saveRace('Southeast Asian')}>Southeast Asian</div>
                        <div className={`option ${race.includes('South Asian/Indian') ? 'selected' : ''}`} onClick={() => saveRace('South Asian/Indian')}>South Asian/Indian</div>
                        <div className={`option ${race.includes('White') ? 'selected' : ''}`} onClick={() => saveRace('White')}>White</div>
                        <div className={`option ${race.includes('Decline to State') ? 'selected' : ''}`} onClick={() => saveRace('Decline to State')}>Decline to State</div>
                    </div>
                </div>
                <div>
                    <p className="faux-label">City *</p>
                    <div className="faux-evergreen-autocomplete-styles">
                        <Autocomplete
                            getItemValue={(item) => item}
                            items={citiesList}
                            open={citiesList.length > 0}
                            renderItem={(item, isHighlighted) =>
                                <div 
                                    key={item.toString()} 
                                    className={`autocomplete-option ${isHighlighted ? 'highlight' : ''}`}
                                >
                                    {item}
                                </div>
                            }
                            value={city}
                            onChange={(e) => getCities(e.target.value)}
                            onSelect={(changedCity) => selectCity(changedCity)}
                            inputProps={{
                                onBlur: () => setCitiesList([])
                            }}
                        />
                    </div>
                </div>
                <div>
                    <p className="faux-label">State *</p>
                    <Select
                        value={state}
                        marginBottom={0}
                        onChange={(e) => setState(e.target.value)}
                    >
                        <option value="">Select a state</option>
                        {states.map((state, i) => (
                            <option key={i} value={state}>{state}</option>   
                        ))}
                    </Select>
                </div>
                <div>
                    <p className="faux-label">What is your job function? *</p>
                    <div className="select-options">
                        <div className={`option ${jobFunction === 'Support' ? 'selected' : ''}`} onClick={() => setJobFunction('Support')}>Support</div>
                        <div className={`option ${jobFunction === 'Customer Experience' ? 'selected' : ''}`} onClick={() => setJobFunction('Customer Experience')}>Customer Experience</div>
                        <div className={`option ${jobFunction === 'UX and Design' ? 'selected' : ''}`} onClick={() => setJobFunction('UX and Design')}>UX and Design</div>
                        <div className={`option ${jobFunction === 'Product Management' ? 'selected' : ''}`} onClick={() => setJobFunction('Product Management')}>Product Management</div>
                        <div className={`option ${jobFunction === 'Engineering' ? 'selected' : ''}`} onClick={() => setJobFunction('Engineering')}>Engineering</div>
                        <div className={`option ${jobFunction === 'Data Management' ? 'selected' : ''}`} onClick={() => setJobFunction('Data Management')}>Data Management</div>
                        <div className={`option ${jobFunction === 'Marketing' ? 'selected' : ''}`} onClick={() => setJobFunction('Marketing')}>Marketing</div>
                        <div className={`option ${jobFunction === 'Account Management ' ? 'selected' : ''}`} onClick={() => setJobFunction('Account Management ')}>Account Management </div>
                        <div className={`option ${jobFunction === 'Sales' ? 'selected' : ''}`} onClick={() => setJobFunction('Sales')}>Sales</div>
                        <div className={`option ${jobFunction === 'Human Resources' ? 'selected' : ''}`} onClick={() => setJobFunction('Human Resources')}>Human Resources</div>
                    </div>
                </div>
                <div>
                    <p className="faux-label">Current Employer *</p>
                    <div className="faux-evergreen-autocomplete-styles">
                        <Autocomplete
                            getItemValue={(item) => item}
                            items={employerNamesList}
                            open={employerNamesList.length > 0}
                            renderItem={(item, isHighlighted) =>
                                <div 
                                    key={item.toString()} 
                                    className={`autocomplete-option ${isHighlighted ? 'highlight' : ''}`}
                                >
                                    {item}
                                </div>
                            }
                            value={selectedEmployerName}
                            onChange={(e) => getEmployers(e)}
                            onSelect={(changedEmployer) => selectEmployer(changedEmployer)}
                            inputProps={{
                                onBlur: () => setEmployerNamesList([])
                            }}
                        />
                    </div>
                </div>
                <div>
                    <p className="faux-label">Start date *</p>
                    <TextInput 
                        type="date"
                        max={currDate}
                        value={startDate}
                        marginBottom={0}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </div>
                <div>
                    <p className="faux-label">Current Position *</p>
                    <TextInput
                        type="text"
                        value={position}
                        marginBottom={0}
                        onChange={(e) => setPosition(e.target.value)}
                    />
                </div>
                <div>
                    <p className="faux-label">Experience Level *</p>
                    <div className="select-options">
                        <div className={`option ${expLevel === 'Intern' ? 'selected' : ''}`} onClick={() => setExpLevel('Intern')}>Intern</div>
                        <div className={`option ${expLevel === 'Entry Level' ? 'selected' : ''}`} onClick={() => setExpLevel('Entry Level')}>Entry Level</div>
                        <div className={`option ${expLevel === 'Associate' ? 'selected' : ''}`} onClick={() => setExpLevel('Associate')}>Associate</div>
                        <div className={`option ${expLevel === 'Mid-Senior' ? 'selected' : ''}`} onClick={() => setExpLevel('Mid-Senior')}>Mid-Senior</div>
                        <div className={`option ${expLevel === 'Manager' ? 'selected' : ''}`} onClick={() => setExpLevel('Manager')}>Manager</div>
                        <div className={`option ${expLevel === 'Director' ? 'selected' : ''}`} onClick={() => setExpLevel('Director')}>Director</div>
                        <div className={`option ${expLevel === 'Executive' ? 'selected' : ''}`} onClick={() => setExpLevel('Executive')}>Executive</div>
                    </div>
                </div>
                <div className="button-component custom">
                    <Button 
                        appearance="primary" 
                        isLoading={isLoading}
                        disabled={
                            __isEmpty(city) ||
                            __isEmpty(state) ||
                            __isEmpty(selectedEmployerName) ||
                            __isEmpty(position) ||
                            __isEmpty(expLevel)
                        }
                        onClick={submitOnboarding}
                    >
                        Continue
                    </Button>
                </div>
            </form>
        </div>
    );
}

Onboarding.propTypes = {
    setPageTitle: PropTypes.func.isRequired
};

export default Onboarding;