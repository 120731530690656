import React, { useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import __isEmpty from 'lodash/isEmpty';

import './Account.scss';

import { 
    TextInputField,
    Button,
    toaster
} from 'evergreen-ui';

const DeleteAccount = ({ router, setPageTitle, setCurrentUser }) => {
    const db = firebase.firestore();
    const [currentStep, setCurrentStep] = useState(1);
    const [password, setPassword] = useState('');

    setPageTitle(`Delete Account - Step ${currentStep} of 3`);

    const returnToAccount = () => {
        const url = router.url({ name: 'Account' });
        router.navigate({ url });
    }

    const returnHome = () => {
        const url = router.url({ name: 'Home' });
        router.navigate({ url });
    }

    const deleteAccount = async () => {
        try {
            const currentUser = firebase.auth().currentUser;
            const snapshot = await db.collection('users').where('email', '==', currentUser.email).get();
            const userRef = snapshot.docs[0].ref;
            const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, password);

            await currentUser.reauthenticateWithCredential(credential);

            toaster.success('Your account and data were successfully removed. You will be redirected in five seconds.');

            setTimeout(async () => {
                await userRef.delete();
                await currentUser.delete();
                await firebase.auth().signOut();
                setCurrentUser(null);
                returnHome();
            }, 5000);
        } catch(error) {
            console.log(error);
            toaster.danger('Something went wrong deleting your account. Please try again or contact us.');
            return;
        }
    }

    return (
        <div className="page-wrapper maxed account">
            {
                currentStep === 1 &&
                <div className="section delete-page">
                    <div className="delete-title">
                        <h2>Deactivate Account</h2>
                        <span>Step 1/3</span>
                    </div>
                    <h3>Are you sure?</h3>
                    <div className="information-block">
                        We would love to keep you. Your data makes it easier to bring more 
                        awareness in the workplace, thus helping us to bring a real change 
                        how traditional companies operate.
                    </div>
                    <div className="button-component custom">
                        <Button 
                            appearance="primary" 
                            onClick={returnToAccount}
                        >
                            Go Back
                        </Button>
                    </div>
                    <div className="button-component minimal">
                        <Button 
                            appearance="minimal" 
                            intent="danger"
                            onClick={() => setCurrentStep(2)}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            }
            {
                currentStep === 2 &&
                <div className="section delete-page">
                    <div className="delete-title">
                        <h2>Deactivate Account</h2>
                        <span>Step 2/3</span>
                    </div>
                    <h3>You should know...</h3>
                    <div className="information-block">
                        In accordance with our privacy policy, all of your data will be removed 
                        and you will not be able to recover it. This is your last chance to reverse 
                        the decision. Are you sure you want to delete your account with Workplace 
                        Tracker?
                    </div>
                    <div className="button-component custom">
                        <Button 
                            appearance="primary" 
                            onClick={returnToAccount}
                        >
                            Go Back
                        </Button>
                    </div>
                    <div className="button-component minimal">
                        <Button 
                            appearance="minimal" 
                            intent="danger"
                            onClick={() => setCurrentStep(3)}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            }
            {
                currentStep === 3 &&
                <div className="section delete-page">
                    <div className="delete-title">
                        <h2>Deactivate Account</h2>
                        <span>Step 3/3</span>
                    </div>
                    <h3>Last Step</h3>
                    <div className="information-block">
                        Please enter your password in order to complete account deletion.
                        <br /><br />
                        <TextInputField
                            label="Enter Your Password"
                            name="password"
                            required
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="button-component custom">
                        <Button 
                            appearance="primary" 
                            onClick={returnToAccount}
                        >
                            Go Back
                        </Button>
                    </div>
                    <div className="button-component minimal">
                        <Button 
                            appearance="minimal" 
                            intent="danger"
                            onClick={deleteAccount}
                            disabled={__isEmpty(password)}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
}

DeleteAccount.propTypes = {
    setPageTitle: PropTypes.func.isRequired
};

export default DeleteAccount;