import React from 'react';

import './RegistrationHeader.scss';

const RegistrationHeader = ({ activeRegState }) => {
    return (
        <div className="reg-header">
            <div className={`header-state ${activeRegState === 1 ? 'active' : ''}`}>
                <span className="step-count">1</span>
                <span className="step-title">Create Account</span>
            </div>
            <div className={`header-state ${activeRegState === 2 ? 'active' : ''}`}>
                <span className="step-count">2</span>
                <span className="step-title">Accept Terms</span>
            </div>
            <div className={`header-state ${activeRegState === 3 ? 'active' : ''}`}>
                <span className="step-count">3</span>
                <span className="step-title">Build Your Profile</span>
            </div>
        </div>
    );
};

export default RegistrationHeader;