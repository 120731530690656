import React, { useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import { datadogLogs } from '@datadog/browser-logs';

import './Login.scss';

import {
    TextInputField,
    Button,
    toaster
} from 'evergreen-ui';

const Login = ({ router, setPageTitle }) => {
    const db = firebase.firestore();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    setPageTitle('Log In');

    const submitLogin = async (e) => {
        e.preventDefault();

        if (window.DD_LOGS) {
            datadogLogs.init({
                clientToken: 'pub07abfad12ae27720e9521aee69f9dc04',
                datacenter: 'us',
            });
        }

        try {
            await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
            await firebase.auth().signInWithEmailAndPassword(email, password);

            const rightNow = firebase.firestore.Timestamp.fromDate(new Date());
            const snapshot = await db.collection('users').where('email', '==', email).get();

            //should only be one result
            await snapshot.docs[0].ref.update({
                lastLogin: rightNow
            });

            const env = process.env.REACT_APP_NODE_ENV;
            // fetch('https://http-intake.logs.datadoghq.com/v1/input', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'DD-API-KEY': '717149de2a737bb436f4d62ac7609ef1',
            //         'DD-APPLICATION-KEY': '7f0e5366330d5eddc7ada726ef939c4074813fcc',
            //     },
            //     body: JSON.stringify({
            //         ddsource: 'firebase',
            //         ddtags: `env:${env},service:login`,
            //         hostname: 'server01', 
            //         eventType: 'UserLogin',
            //         userId: email
            //     })
            // })
            // .then((response) => response.json())
            // .then((data) => {
            //     console.log('Success:', data);
            // })
            // .catch((error) => {
            //     console.error('Error:', error);
            // }).finally(() => {
                window.DD_LOGS && datadogLogs.logger.info('UserLogin', { env: env, userId: email });

                const url = router.url({ name: 'Home' });
                router.navigate({ url });
            // });
        } catch(error) {
            showLoginError(error);
            return;
        }
    };

    const showLoginError = (error) => {
        if (error.code) {
            switch(error.code) {
                case 'auth/user-not-found':
                case 'auth/wrong-password':
                    toaster.danger('The email/password combination you entered is invalid.');
                    break;
                case 'auth/invalid-email':
                    toaster.danger('Please enter a valid email address.');
                    break;
                default:
                    console.log(error);
                    toaster.danger('Something went wrong with logging in. Please try again or contact us.');
                    break;
            };
        } else {
            console.log(error);
            toaster.danger('Something went wrong with logging in. Please try again or contact us.');
        }
    };

    return (
        <div className="page-wrapper login">
            <p className="login-desc">Welcome back! Log in below.</p>
            <form onSubmit={submitLogin}>
                <TextInputField
                    label="Email"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextInputField
                    label="Password"
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className="button-component custom">
                    <Button
                        appearance="primary"
                        onClick={submitLogin}
                    >
                        Log In
                    </Button>
                </div>
            </form>
        </div>
    );
}

Login.propTypes = {
    setPageTitle: PropTypes.func.isRequired
};

export default Login;