import React from 'react';
import { Link } from '@curi/react-dom';

import './FooterPages.scss';

const Slack = () => {
    return (
        <div className="footer-page">
            <div className="slack">
                <h1>Add Good Job to your Slack Org</h1>
                <p>
                    Good Job for Slack allows you to check in with how you're feeling during your work day. To use it, you'll need to have a Good Job account, so if you don't have one already, please first go to heygoodjob.com and create your account.
                    <br /><br />
                    <b>How to Install Good Job to your workspace</b>
                    <br /><br />
                    For you to use Good Job in Slack, it must first be installed by your workspace admin. Click the button below and follow the prompts, granting the requested permissions to install on your workspace:
                    <br /><br />
                    <span className="slack-button">
                        <a target="_top" href="https://slack.com/oauth/authorize?client_id=86756720979.846441579824&scope=bot">
                            <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
                        </a>
                    </span>
                    <br />
                    Once your admin has installed Good Job to your workspace, open a direct message session. If you don't already see Good Job under the Direct Messages user list, hit the + button next to Direct Messages to add it.
                    <br /><br />
                    <b>Linking your Good Job Account</b>
                    <br /><br />
                    The first time you interact with Good Job in Slack you'll be prompted to link your account. During this process you'll need to email address and password you used to create your Good Job account to log in.
                    <br /><br />
                    <b>Using the Good Job bot</b>
                    <br /><br />
                    After linking your account you can "Check in" with how you're feeling at work by sending a direct message to the Good Job bot. Good Job will ask you how you are feeling. You may select "good" "bad" or "neutral," and Good Job will record that feeling to your timeline in the Good Job app. You can add more context to the check in by editing the event in your timeline.
                    <br /><br />
                    If you're using the Desktop version of Slack, you have one additional way to interact with Good Job. You'll find an additional Apps heading in the lower left, and you can use the + button there to add Good Job. Once it's in the list, just click on it at any time.
                    <br /><br />
                    <b>Check in Reminders</b>
                    <br /><br />
                    Good Job will remind you to "Check in" via Slack once in the morning and once in the afternoon. You can mute these reminders at any time by selecting "mute conversation" in the settings of the Direct Message with Good Job.
                    <br /><br />
                    <b>Questions?</b>
                    <br /><br />
                    <Link name="Contact">Contact us at any time.</Link>
                </p>
            </div>
        </div>
    );
};

export default Slack;