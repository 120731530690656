import React, { useState, useEffect } from 'react';
import firebase from 'firebase';
import { FireSQL } from 'firesql';
import 'firebase/firestore';
import moment from 'moment';
import __find from 'lodash/find';
import __merge from 'lodash/merge';
import __unset from 'lodash/unset';
import __startCase from 'lodash/startCase';
import __filter from 'lodash/filter';

import '../Admin.scss';

import {
    Table,
    Textarea,
    Button,
    toaster
} from 'evergreen-ui';

const SQL_JOIN = 'JOIN';
const SQL_ON = 'ON';
const SQL_WHERE = 'WHERE';
const SQL_AND = 'AND';

const CustomQuery = () => {
    const db = firebase.firestore();
    const fireSQL = new FireSQL(firebase.firestore());
    const [query, setQuery] = useState('');
    const [usersList, setUsersList] = useState([]);
    const [results, setResults] = useState(false);
    const [sampleResult, setSampleResult] = useState(null);
    
    useEffect(() => {
        const getUsers = async () => {
            try {
                const snapshot = await db.collection('users').get();
                let users = [];
                snapshot.forEach(async (doc) => {
                    const data = doc.data();
                    users.push(data);
                });

                setUsersList(users);
            } catch(error) {
                console.log(error);
                toaster.danger('Something went wrong retrieving your data. Please refresh the page or contact us.');
            }
        };

        getUsers();

        return () => {};
    }, [db]);

    const submitQuery = async (e) => {
        e.preventDefault();
        
        let isDateQuery = false;
        let submittedQuery = query;
        let fromTable = query.substring(query.indexOf('FROM ')).replace('FROM ', '');
        fromTable = query.includes('WHERE') ? fromTable.substring(0, query.indexOf(' ')) : fromTable;


        if (query === '') {
            toaster.danger('The query cannot be empty.');
            return false;
        }

        if (
            query.includes('SELECT *\nFROM users\nWHERE lastLogin') || 
            query.includes('SELECT *\nFROM users\nWHERE created') ||
            query.includes('SELECT *\nFROM events\nWHERE created') ||
            query.includes('SELECT *\nFROM events\nWHERE eventDate') ||
            query.includes('SELECT *\nFROM employersByUsers\nWHERE endDate') ||
            query.includes('SELECT *\nFROM employersByUsers\nWHERE startDate') ||
            query.includes('SELECT *\nFROM questionsAnswered\nWHERE dateAnswered')
        ) {
            isDateQuery = true;
        }
        
        let joinQuery = '';
        if (!isDateQuery) {
            if (query.includes(SQL_JOIN) && !query.includes(SQL_ON)) {
                toaster.danger('Your query is invalid.');
                return false;
            } else if (query.includes(SQL_ON) && !query.includes(SQL_JOIN)) {
                toaster.danger('Your query is invalid.');
                return false;
            } else if (query.includes(SQL_JOIN) && query.includes(SQL_ON)) {
                //save the join portion until after we get our base results
                joinQuery = query.substr(query.indexOf(SQL_JOIN));
                const newQuery = query.replace(joinQuery, '');
                submittedQuery = newQuery.trim();
            }
        }

        try {
            let results = [];
            if (isDateQuery) {
                const conditions = (query.substr(query.indexOf(SQL_WHERE))).replace(`${SQL_WHERE} `, '');
                let firstClause = '';
                let secondClause = '';
                if (conditions.includes(SQL_AND)) {
                    firstClause = (conditions.substr(0, conditions.indexOf(SQL_AND))).trim().split(' ');
                    secondClause = (conditions.substr(conditions.indexOf(SQL_AND))).replace(`${SQL_AND} `, '').trim().split(' ');
                } else {
                    firstClause = conditions.trim().split(' ');
                }

                let userTimeQuery = db.collection(fromTable).where(firstClause[0], firstClause[1], new Date(firstClause[2]));
                if (secondClause !== '') {
                    userTimeQuery = userTimeQuery.where(secondClause[0], secondClause[1], new Date(secondClause[2]));
                }

                const resultsSnap = await userTimeQuery.get();
                resultsSnap.forEach(async (doc) => {
                    results.push(doc.data());
                });
            } else {
                results = await fireSQL.query(submittedQuery);
            }
    
            let optimizedResults = [];
            for (const result of results) {
                for (const key in result) {
                    if (result[key]) {
                        if (result[key]['seconds']) {
                            result[key] = new Date(result[key]['seconds'] * 1000).toLocaleDateString('en-US');
                        } else if (result[key]['city']) {
                            result[key] = `${result[key]['city']}, ${result[key]['state']}, ${result[key]['country']}`
                        } else if (key === 'positions') {
                            result[key] = (result[key]).length
                        } else if (key === 'audience') {
                            let audienceStr = '';
                            (result[key]).forEach((audience) => {
                                audienceStr += `${audience}, `;
                            });
                            result[key] = audienceStr;
                        }
                    }
                }
                optimizedResults.push(result);
            }

            if (joinQuery) {
                if (joinQuery.includes('users')) {
                    for (const result of optimizedResults) {
                        for (const user of usersList) {
                            if (result.userId === user.profileId) {
                                const dataToMerge = {
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    gender: user.gender,
                                    location: user.location
                                };
                                __merge(result, dataToMerge);
                                __unset(result, 'userId');
                            }
                        }
                    }
                }

                if (joinQuery.includes(SQL_WHERE)) {
                    const whereQuery = joinQuery.substr(joinQuery.indexOf(SQL_WHERE));
                    const queryComponents = whereQuery.split(' ');

                    const subjects = [];
                    const operands = [];
                    const values = [];
                    for (let i = 1; i < queryComponents.length; i++) {
                        if (i === 1 || i === 5 || i === 9 || i === 13) {
                            subjects.push(queryComponents[i].trim());
                        } else if (i === 2 || i === 6 || i === 10 || i === 14) {
                            operands.push(queryComponents[i].trim());
                        } else if (i === 3 || i === 7 || i === 11 || i === 15) {
                            values.push(queryComponents[i].trim().replace(/'/g,''));
                        }
                    }

                    if (subjects.length !== operands.length || subjects.length !== values.length) {
                        throw new Error('Your query is invalid.');
                    }

                    for (let i = 0; i < subjects.length; i++) {
                        optimizedResults = __filter(optimizedResults, (result) => {
                            if (operands[i] === '=') {
                                const subject = subjects[i];
                                return result[subject] === values[i];
                            }
                        });
                    }
                }
            }

            //we need the max number of props to evenly fill out the table
            let maxPropCount = 0;
            for (const result of optimizedResults) {
                let propCount = 0;
                for (const key in result) {
                    propCount += 1;
                    if (result[key] && result[key]['city']) {
                        result[key] = `${result[key]['city']}, ${result[key]['state']}, ${result[key]['country']}`
                    }
                    
                    if (propCount > maxPropCount) {
                        maxPropCount = propCount;
                    }
                }
            }

            const sample = __find(optimizedResults, (result) => {
                return Object.keys(result).length === maxPropCount;
            });

            setSampleResult(sample);
            setResults(optimizedResults);
        } catch(error) {
            console.log(error);
            toaster.danger('Something is wrong with this query. Check the developer tools console for the error and send it to your beloved dev :-)');
        }
    };

    const downloadData = () => {
        const filename = `gj_query__${moment().format('MM-DD-YY_HH-mm')}.csv`;
        let csvString = '';

        //headers
        Object.keys(sampleResult).forEach((key, i) => {
            csvString += `${__startCase(key)}`;

            if (i + 1 === Object.keys(sampleResult).length) {
                csvString += '\n';
            } else {
                csvString += ',';
            }
        });

        //body
        results.forEach((result) => {
            Object.keys(sampleResult).forEach((key, i) => {
                csvString += !result[key] ? 'N/A' : `"${result[key]}"`;
    
                if (i + 1 === Object.keys(sampleResult).length) {
                    csvString += '\n';
                } else {
                    csvString += ',';
                }
            });
        });

        const csvFile = new Blob([csvString], {type: 'text/csv'});
        const downloadLink = document.createElement('a');
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();

        setTimeout(() => {
            document.body.removeChild(downloadLink);
        }, 1000);
    };

    return (
        <>
            <h2 className="admin-header">Custom Query</h2>
            <form className="query-form">
                <Textarea
                    value={query}
                    placeholder="Type your query here..."
                    width={400}
                    marginRight={15}
                    spellCheck={false}
                    onChange={(e) => setQuery(e.target.value)}
                />
                <div className="button-component custom">
                    <Button 
                        appearance="primary" 
                        disabled={query === ''}
                        onClick={submitQuery}
                    >
                        Submit Query
                    </Button>
                </div>
            </form>
            {
               !results ? (
                    <div className="information-block">
                        Please enter a query above...
                    </div>
               ) : results.length === 0 ? (
                    <div className="information-block">
                        No information for this query
                    </div>
               ) : (
                    <div className="information-block">
                        <div className="info-table-preheader">
                            <div>Count: {results.length}</div>
                            <div className="button-component">
                                <Button 
                                    appearance="default" 
                                    iconBefore="download"
                                    onClick={downloadData}
                                >
                                    Download
                                </Button>
                            </div>
                        </div>
                        <br />
                        <Table>
                            <Table.Head>
                                {
                                    Object.keys(sampleResult).map((key) => (
                                        <Table.TextHeaderCell>
                                            {__startCase(key)}
                                        </Table.TextHeaderCell>
                                    ))
                                }
                            </Table.Head>
                            <Table.Body height={450}>
                                {results.map(result => {
                                    return (
                                        <Table.Row key={Math.random() * 98180182145}>
                                            {
                                                Object.keys(sampleResult).map((key) => {
                                                    if (!result[key]) {
                                                        return <Table.TextCell>N/A</Table.TextCell>;
                                                    } 
                                                  
                                                    return <Table.TextCell>{result[key]}</Table.TextCell>;
                                                })
                                            }
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    </div>
               )
            }
        </>
    );
}

export default CustomQuery;